import React from 'react';
import { Button, Flex } from 'antd';
import styles from './Button.module.css';

interface GenericButtonProps{
   label?:string;
   type?:"primary" | "default";
   onClick?:()=>void;
   style?:React.CSSProperties;
}
const GenericButton: React.FC<GenericButtonProps> = ({label,type = "default", onClick, style}) => (
    <Button type={type} style={style} onClick={onClick}>{label}</Button>
);

export default GenericButton;