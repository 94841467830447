import React from "react";
import styles from "./TotalCountsCard.module.css";
interface GenericTotalCountsCard {
  label?: string;
  count?: number;
  borderColor?:string
}
const TotalCountsCard: React.FC<GenericTotalCountsCard> = ({
  label,
  count,
  borderColor
}) => {
  return (
    <div
      className={styles.mainContainer}
      style={{
        background:
          label === "In Business hours"
            ? "linear-gradient(178.73deg, #0A1F37 40.29%, #326FB6 114.13%)"
            : "",
        border:`2px solid ${borderColor?borderColor:"white"}`
      }}
    >
      <div
        className={styles.countTypographyWrapper}
        style={{
          background:
            label === "Stores with issues"
              ? "linear-gradient(2.06deg, #000000 28.4%, #F64209 83.02%)"
              : "",
        }}
      >
        {label !== "Stores with issues" ? (
          <div
            className={styles.countTypography}
          >
            {count}
          </div>
        ) : (
          <div className={styles.textLabel}>Work In Progress</div>
        )}
      </div>
      <div
        className={styles.labelWrapper}
        style={{
          color: label === "In Business hours" ? "#ffffff" : "#000000",
        }}
      >
        {label}
      </div>
    </div>
  );
};

export default TotalCountsCard;
