import React, { useState } from "react";
import styles from "./Network.module.css";
import {
  Dropdown,
  Card,
  Button,
  Space,
  Grid,
  Picker,
  CapsuleTabs,
} from "antd-mobile";
import { Link } from "react-router-dom";
import "../../assets/scss/_customMobile.scss";
import AppHeader from "../../components/AppHeader";
import { GetUserName } from "../../services/AuthUser";
import AppHeaderMobile from "../../components/AppHeaderMobile";
const Network = () => {
  const [selectedValue, setSelectedValue] = useState(null);

  const handlePickerChange = (value: any) => {
    setSelectedValue(value);
    // Add logic here based on the selected value
  };
  const loadStoreVisualizer = () => {
    console.log("onBodyClick");
  };
  const loadGlobalVisualizer = () => {
    console.log("onBodyClick");
  };
  return (
    <div className={styles?.MobileHomePage}>
      <AppHeaderMobile userDetails={GetUserName()} />
      <div className={styles?.Content}>
        <div className={styles?.container}>
          <div className={styles?.item}><Link to="http://localhost:4200/">
            <Card
              className={styles.homeCard}
              onBodyClick={loadStoreVisualizer}
            >
              <span
                className={styles?.InStoreCareIcon}
              ></span>
              <div>Store Network</div>
            </Card>
          </Link></div>
          <div className={styles?.item}><Link to="/chat">
            <Card
              className={styles.homeCard}
              onBodyClick={loadGlobalVisualizer}
            >
              <span
                className={styles?.SmartphoneIcon}
              ></span>
              <div>Store IService</div>
            </Card>
          </Link></div>
        </div>
      </div>
    </div>
  );
};
export default Network;
