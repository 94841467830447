import styles from "./Header.module.css";

interface Props{
  pageTitle?:string
}
const Header = ({pageTitle}:Props) => {
  return (
    <div className={styles.header}>
      <div className={styles.headerTitleWrapper}>
        <span className={styles.headerLogo}></span>
       <div className={styles.headerDivider} />
        <div className={styles.headerFont}>{pageTitle}</div>
      </div>
    </div>
  );
};

export default Header;
