import { Chart } from "react-google-charts";

interface Item{
}

interface PieChartProps{
chartData:[[string,number],[string,number]]
chartColors:string[]
}

function PieChart({chartData, chartColors}:PieChartProps) {
  const data = [
    ["Category", "value"],
    // ["% of stores with at least one device synced to network BDD", 11],
    // ["Not Synced", 2],
    ...chartData

  ];

  const options = {
    title: "",
    pieHole: 0.4, // Creates a Donut Chart. Does not do anything when is3D is enabled
    is3D: true, // Enables 3D view
    pieSliceText:'none',
    // slices: {
    //   1: { offset: 0.2 }, // Explodes the second slice
    // },
    pieStartAngle: 50, // Rotates the chart
    // sliceVisibilityThreshold: 0.02, // Hides slices smaller than 2%
    legend: 'none',
    colors: chartColors,
  };
  return (
    <Chart
      chartType="PieChart"
      data={data}
      options={options}
      width={"100%"}
      height={"350px"}
    />
  )
}

export default PieChart