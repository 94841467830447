// LocalStorageService.ts

export const setJsonItem = (key: string, value: Record<string, any>): void => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getJsonItem = <T>(key: string): T | null => {
  const item = localStorage.getItem(key);
  return item ? JSON.parse(item) : null;
};
export const getAllItems = (): Record<string, any>[] => {
  const allItems: Record<string, any>[] = [];

  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key && key.startsWith("aicompanion-")) {
      const value = localStorage.getItem(key);
      if (value) {
        allItems.push({ [key]: JSON.parse(value) });
      }
    }
  }

  return allItems;
};

export const generateSessionId = (): string => {
  const timestamp = Date.now().toString();
  const random = Math.random().toString(36).substring(2, 15);
  const dateObject = new Date();
  const dateString = dateObject.toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  const sessionId =
    "aicompanion-" + dateString + "-" + timestamp + "-" + random;
  return sessionId;
};
