import React from "react";
import styles from "./Recommendation.module.css";
import { CCard, CCardImageOverlay, CModal, CRow } from "@coreui/react";
import { useAppContext } from "../ContextAndProvider/Context";
import VideoModal from "./VideoModal";
interface RecommendationTabProps {
  historydata?: any;
  historyOnlickfunction?: any;
}
const RecommendationTab: React.FC<RecommendationTabProps> = ({
  historydata,
  historyOnlickfunction,
}) => {
  const {
    videoModal,
    setVideoModal,
    isSidebarOpen,
    setSidebarOpen,
    activeRecommendation,
    setActiveRecommendation,
    mockFileData,
    mockLinkData,
    mockHistoryData,
    visibleModal,
    setVisibleModal,
    isMobile,
  } = useAppContext();

  const closeModal = () => {
    setVideoModal(false);
  };
  const getIcon = (fileTitle: string) => {
    const fileExtension = fileTitle.split(".").pop();
    if (fileExtension === "pdf" || fileTitle === "PDFFileTitle") {
      return "spriteUrl pdfIcon";
    } else if (fileExtension === "docx" || fileTitle === "WordFileTitle") {
      return "spriteUrl wordIcon";
    } else if (fileExtension === "xlsx" || fileTitle === "ExcelFileTitle") {
      return "spriteUrl excelIcon";
    } else if (fileExtension === "pptx" || fileTitle === "PPTFileTitle") {
      return "spriteUrl pptIcon";
    }
  };

  return (
    <div>
      <div
        className={`${styles?.aiSidebar} ${isMobile ? styles?.mobSideBar : ""}`}
      >
        <CRow className={styles?.recommendationHeading}>
          <div className={styles.displayProperty}>
            <div className="InlineDisplayProperty">
              <span
                className={` ${styles.spriteUrl} ${
                  activeRecommendation + "Icon" + " " + "InlineDisplayProperty"
                }`}
              ></span>
              {activeRecommendation}
            </div>
            <div
              className={`${styles.spriteUrl} ${styles.closeIcon}`}
              onClick={() => {
                setSidebarOpen(false);
                setVisibleModal(false);
              }}
            ></div>
          </div>
        </CRow>
        <CRow className={styles?.recomDataContainer}>
          {activeRecommendation === "History" &&
            Object?.keys(historydata)?.map((historyDate) => {
              return (
                <div className={styles?.historyList}>
                  <div className={styles?.historyHeading}>{historyDate}</div>
                  {historydata[historyDate]?.map((historyItem: any) => {
                    return (
                      <div
                        className={`${styles?.fileList} ${styles?.historyContainer}`}
                        onClick={() =>
                          historyOnlickfunction(
                            historyItem[Object?.keys(historyItem)[0]]
                          )
                        }
                      >
                        {historyItem[Object?.keys(historyItem)[0]]
                          ?.find((item: any) => item?.role === "user")
                          ?.content?.replace("GrammerlyContent", "")}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          {activeRecommendation === "Files" &&
            mockFileData?.map((fileTitle) => {
              return (
                <div
                  className={`${styles?.fileList} ${styles?.displayProperty}`}
                >
                  <div className={styles?.displayProperty}>
                    <span className={getIcon(fileTitle)}></span>
                    {fileTitle}
                  </div>
                  <div>
                    <span
                      className={`${styles?.spriteUrl} ${styles?.downloadIcon}`}
                    ></span>
                    <span
                      className={`${styles?.spriteUrl} ${styles?.shareIcon}`}
                    ></span>
                  </div>
                </div>
              );
            })}
          {activeRecommendation === "Links" &&
            mockLinkData?.map((fileTitle) => {
              return (
                <div className={styles?.fileList}>
                  <a href={fileTitle}>{fileTitle}</a>
                </div>
              );
            })}
          {activeRecommendation === "Watch Videos" && (
            <div className="px-0">
              <div className={styles?.historyHeading}>Video Title1</div>
              <CCard className={styles?.imageCard}>
                <video
                  className={styles?.rawVideoImg}
                  src="https://saaicompanion.blob.core.windows.net/videos-container/What's next for AI and Google Search _ Google I_O 2023.mp4"
                />
                <CCardImageOverlay className="InlineDisplayProperty">
                  <div
                    className={`${styles?.spriteUrl} ${styles?.playIcon}`}
                    onClick={() => {
                      setVideoModal(true);
                    }}
                  ></div>
                </CCardImageOverlay>
              </CCard>
            </div>
          )}
        </CRow>
      </div>

      <CModal
        alignment="center"
        scrollable
        size={"xl"}
        visible={videoModal}
        onClose={() => closeModal()}
        className="videoModal"
      >
        <div className={styles?.ModalCloseBtn}>
          <span
            onClick={() => closeModal()}
            className={`${styles?.spriteUrl} ${styles?.modalCloseIcon}`}
          ></span>
        </div>
        <VideoModal />
      </CModal>
    </div>
  );
};
export default RecommendationTab;
