import React, { useEffect, useState } from "react";
import {
  CNavItem,
  CNavLink,
  CContainer,
  CHeader,
  CHeaderNav,
  CAvatar,
  CPopover,
  CRow,
  CCol,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CFormCheck,
} from "@coreui/react";

// import AppBreadcrumb from "./AppBreadcrumb";
import appLogo from "../assets/brand/hm-logo.png";

import { useMsal } from "@azure/msal-react";
import "../assets/scss/_headertitle.scss";
// import { getAppInsights } from "../services/TelemetryService";
import { getInitials } from "./CommonUtils/CommonUtils";
import {
  environmentData,
  EnvironmentData,
  EnvironmentConfig,
} from "../environments/env";
import { useAppContext } from "./ContextAndProvider/Context";
interface AppHeaderProps {
  userDetails: any[];
}

const AppHeader: React.FC<AppHeaderProps> = ({ userDetails }) => {
  const { instance } = useMsal();
  let runEnv: keyof EnvironmentData =
    environmentData.run as keyof EnvironmentData;

  const environmentConfig: EnvironmentConfig = environmentData[
    runEnv
  ] as EnvironmentConfig;
  // let appInsights

  // useEffect(() => {
  //   appInsights = getAppInsights()
  // }, [])

  const handleLogout = (logoutType: string) => {
    if (logoutType === "redirect") {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
      sessionStorage.clear();
      localStorage.clear();
      instance.logoutRedirect();
    }
  };
  const { modelValue, setModelValue } = useAppContext(); // Default selected option
  console.log("modelValue", modelValue);
  const handleOptionChange = (option: any) => {
    setModelValue(option);
    console.log("option", option);
  };

  return (
    <CHeader position="sticky">
      <CContainer fluid className="px-0">
        <CRow className="w-100 mx-0">
          <CCol md={2} className="d-flex align-items-center">
            <CHeaderNav className="d-none d-md-flex">
              <CNavItem className="py-1 me-4">
                <img src={appLogo} alt="Logo" className="sidebar-icon" />
                {/* <h1 style={{ color: "white" }}>TCS</h1> */}
              </CNavItem>
            </CHeaderNav>
          </CCol>
          <CCol className="d-flex justify-content-center align-items-center">
            <CHeaderNav className="d-none d-md-flex mx-auto">
              <CNavItem>
                {/* <div className="headertitle">AI Companion</div> */}
                <CDropdown className="ChatModelDropDown">
                  <CDropdownToggle className="ChatDropDownToggle">
                  Store IService
                  </CDropdownToggle>
                  <CDropdownMenu className="ChatModelDropDown">
                    <CDropdownItem>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        onClick={() =>
                          handleOptionChange(environmentConfig.gpt3key)
                        }
                      >
                        <span>GPT-3.5</span>
                        <CFormCheck
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          checked={modelValue === environmentConfig.gpt3key}
                          onChange={() =>
                            handleOptionChange(environmentConfig.gpt3key)
                          }
                        />
                      </div>
                    </CDropdownItem>
                    <CDropdownItem>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        onClick={() =>
                          handleOptionChange(environmentConfig.gpt4key)
                        }
                      >
                        <span>GPT-4</span>
                        <CFormCheck
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          checked={modelValue === environmentConfig.gpt4key}
                          onChange={() =>
                            handleOptionChange(environmentConfig.gpt4key)
                          }
                        />
                      </div>
                    </CDropdownItem>
                  </CDropdownMenu>
                </CDropdown>
              </CNavItem>
            </CHeaderNav>
          </CCol>
          <CCol
            md={2}
            className="d-flex justify-content-end align-items-center"
          >
            <CHeaderNav>
              <CNavItem></CNavItem>
            </CHeaderNav>
            <CHeaderNav>
              <CNavItem className="d-flex align-items-center">
                <CAvatar
                  className="profileAvatar"
                  data-coreui-toggle="tooltip"
                  data-coreui-placement="bottom"
                  title={userDetails?.[0]?.userName}
                  textColor="white"
                  data-testid="userName"
                >
                  {userDetails.length > 0 &&
                    getInitials(userDetails?.[0]?.userName)}
                </CAvatar>
              </CNavItem>
              <CNavItem>
                <CNavLink
                  onClick={() => handleLogout("redirect")}
                  data-testid="logoutIcon"
                >
                  <CAvatar className="circleIcon">
                    <span className="logoutSpriteIcon"></span>
                  </CAvatar>
                </CNavLink>
              </CNavItem>
            </CHeaderNav>
          </CCol>
        </CRow>
      </CContainer>
    </CHeader>
  );
};

export default AppHeader;
