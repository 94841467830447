import React, { useEffect, useState } from "react";
import styles from "./InstoreCustCare.module.css";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Divider } from "antd";
import Header from "../CommonComponents/Header";
import CustomTable from "../../components/CommonComponents/Table";
import {
  environmentData,
  EnvironmentData,
  EnvironmentConfig,
} from "../../environments/env";
import { GetAccessToken } from "../../services/AuthToken";

const InstoreCustCare = () => {
  let runEnv: keyof EnvironmentData =
    environmentData.run as keyof EnvironmentData;
  const environmentConfig: EnvironmentConfig = environmentData[
    runEnv
  ] as EnvironmentConfig;
  const navigate = useNavigate();
  const [inStoreCareData, setInStoreCareData] = useState([]);
  const columns = [
    {
      title: "Response Times ",
      dataIndex: "operationName",
      key: "operationName",
      width: 200,
      render: (text: any) => (
        <a style={{ fontFamily: "HM Sans", fontSize: 14, fontWeight: 500 }}>
          {text}
        </a>
      ),
    },
    {
      title: "Time(MS)",
      dataIndex: "responseTime_ms",
      key: "responseTime_ms",
      width: 150,
      render: (text: any, item: any) => (
        <a
          style={{
            fontFamily: "HM Sans",
            fontSize: 14,
            fontWeight: 500,
            color: item?.colorCode,
          }}
        >
          {text}
        </a>
      ),
    },
    // {
    //   title: "Set Alert",
    //   dataIndex: "setalert",
    //   key: "setalert",
    //   render: (text) => <Checkbox></Checkbox>,
    // },
    {
      title: "THRESHOLD",
      dataIndex: "threshold",
      key: "threshold",
      width: 150,
      render: (text: any) => (
        <a style={{ fontFamily: "HM Sans", fontSize: 14, fontWeight: 500 }}>
          {2000}
        </a>
      ),
    },
    {
      title: "LOG Date",
      dataIndex: "logDate",
      key: "logDate",
      width: 150,
      render: (text: any) => (
        <a style={{ fontFamily: "HM Sans", fontSize: 14, fontWeight: 500 }}>
          {moment(text).format("DD-MM-YYYY HH:mm")}
        </a>
      ),
    },
  ];
  useEffect(() => {
    const fetchInstoreDetails = async () => {
      let token = await GetAccessToken();
      try {
        const response = await fetch(
          `${environmentConfig.chatApiUrl}/StoreReport/GetSplunkPerformance`,
          {
            method: "GET",
            headers: {
              Accept: "text/plain",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.text(); // Assuming the response is text/plain
        const parsedData = JSON.parse(data);
        setInStoreCareData(parsedData);
      } catch (error) {
        console.error("Error fetching instorecare details:", error);
      }
    };

    fetchInstoreDetails();
  }, []);

  return (
    <div className={styles.StoreNotifications}>
      <div className={styles.AppHeaderDesktop}>
        <Header pageTitle="Business Command Center" />
      </div>
      <div className={styles.StoreNotificationsContainer}>
        <div style={{ marginBottom: "20px" }}>
          <a
            className={styles.breadCrumbsLink}
            style={{ paddingLeft: "0px" }}
            onClick={() => navigate("/")}
          >
            Home
          </a>
          <a>&gt;</a>
          <a
            className={styles.breadCrumbsLink}
            onClick={() => {
              navigate("/applicationhealth");
            }}
          >
            Application Health
          </a>
          <a>&gt;</a>
          <a
            className={styles.breadCrumbsLink}
            style={{ textDecoration: "underline", cursor: "auto" }}
          >
            Instore Customer
          </a>
        </div>

        <div className={styles.ApplicationHealthHeader}>
          <h2 className={styles.StoreNotificationTitle}>
            Instore Cust Care - Prod
          </h2>
        </div>
        <Divider
          style={{
            marginTop: "12px",
            marginBottom: "30px",
            height: "1px",
            backgroundColor: "#cccccc",
          }}
        ></Divider>
        <div className={styles.tableContainer}>
          <CustomTable columns={columns} data={inStoreCareData} />
        </div>
        {/* <Button className={styles.submitNotificationBtn}>
          Send Customer Alert
        </Button> */}
      </div>
    </div>
  );
};
export default InstoreCustCare;
