import React, { createContext, useContext } from "react";

interface Item {
  label: string;
  value: boolean;
}

interface UserConfig {
  Rephrase: Item[];
  AdjustTone: Item[];
}
type BlobLinkType = {
  files: string[];
  links: string[] | null;
  videos: string[];
};
export type ChatMessage = {
  role: string;
  content: string;
  end_turn?: boolean;
  callback?: Function;
  noAnsCallback?: Function;
  email?: string;
  reward?: number;
  penalty?: number;
};
interface SidebarContextProps {
  popoverVisible: boolean;
  setPopoverVisible: (value: boolean) => void;
  likeStatus: boolean;

  setLikeStatus: (value: boolean) => void;
  dislikeStatus: boolean;
  setDislikeStatus: (value: boolean) => void;
  isSidebarOpen: boolean;
  setSidebarOpen: (value: boolean) => void;
  answerStatus: boolean;
  setAnswerStatus: (value: boolean) => void;
  visibleModal: boolean;
  isMobile: boolean;
  setIsMobile: (value: boolean) => void;
  setVisibleModal: (value: boolean) => void;
  videoModal: boolean;
  setVideoModal: (value: boolean) => void;
  activeRecommendation: string;
  setActiveRecommendation: (value: string) => void;
  generatedAns: string;
  setGeneratedAns: (value: string) => void;
  mockFileData: Array<string>;
  mockLinkData: Array<string>;
  mockHistoryData: { [key: string]: Array<string> };
  isFocusTextField: boolean;
  setIsFocusTextField: (value: boolean) => void;
  selectedSystem: string;
  setSelectedSystem: (value: string) => void;
  gramerlyContent: UserConfig;
  setGramerlyContent: (value: UserConfig) => void;
  answers: ChatMessage[];
  setAnswers: (value: ChatMessage[]) => void;
  grammarPopVisible: boolean;
  setGrammarlyPopVisible: (value: boolean) => void;
  sampleQnPopVisible: boolean;
  setSampleQnPopVisible: (value: boolean) => void;
  activeGrammar: String | null;
  setActiveGrammar: (value: String | null) => void;
  modelValue: String | null;
  setModelValue: (value: String | null) => void;
  open360Modal: boolean;
  setOpen360Modal: (value: boolean) => void;
  clearCustCounterStore: boolean;
  setClearCustCounterStore: (value: boolean) => void;
  clearDropDownStore: boolean;
  setClearDropDownStore: (value: boolean) => void;
}
const AppContext = createContext<SidebarContextProps | undefined>(undefined);

export const useAppContext = () => {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }

  return context;
};
export default AppContext;
