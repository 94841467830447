import React from 'react';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import styles from "./ExportToExcel.module.css"

interface ExportToExcel {
    jsonData: any[];
    fileName: string;
    headers: { label: string, key: string }[];
}

const ExportToExcel: React.FC<ExportToExcel> = ({ jsonData, fileName, headers }) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToExcel = (jsonData: any[], fileName: string, headers: { label: string, key: string }[]) => {
        // Create an array of custom header labels
        const headerLabels = headers.map(header => header.label);

        // Map JSON data to include only the keys specified in headers
        const dataWithCustomHeaders = jsonData.map(item => 
            headers.map(header => item[header.key])
        );

        // Combine headers and data
        const dataToExport = [headerLabels, ...dataWithCustomHeaders];

        // Convert to worksheet
        const ws = XLSX.utils.aoa_to_sheet(dataToExport);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    return (
        <button  className={styles.exportButton} onClick={() => exportToExcel(jsonData, fileName, headers)}>Export</button>
    );
};

export default ExportToExcel;
