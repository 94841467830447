import React, { useState } from "react";
import { DatePicker, Space } from "antd";
import moment, { Moment } from "moment";
import dayjs from "dayjs";
import styles from "./DatePicker.module.css";
import { setDate } from "date-fns";

interface Props {
  onDateChange?: any;
  calendarDate?:any;
}


const DatePickerComponent: React.FC<Props> = ({ onDateChange, calendarDate}) => {
  const dateFormat = "DD-MM-YYYY";
  return (
    <Space
      size={12}
      direction="vertical"
      className={styles.datepickerInputcontainer}
    >
      <DatePicker
        onChange={onDateChange}
        format={dateFormat}
        className={styles.datePickerStyle}
        value={calendarDate ? calendarDate : dayjs()}
      />
    </Space>
  );
};

export default DatePickerComponent;
