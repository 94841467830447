import React, { useEffect, useMemo, useState } from "react";
import { useBoolean } from "@fluentui/react-hooks";
import {
  FontIcon,
  Stack,
  Text,
  PrimaryButton,
  DefaultButton,
  DefaultEffects,
} from "@fluentui/react";
import { invokeApi } from "../../api";

import styles from "./Answer.module.css";

import { AskResponse, Citation } from "../../api";
import { parseAnswer } from "./AnswerParser";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import supersub from "remark-supersub";
import { useAppContext } from "../ContextAndProvider/Context";
import { CButton, CCol, CFormInput, CFormLabel, CRow } from "@coreui/react";
import Recommendation from "../Recommendation/Recommendation";
import vertex_loader from "../../assets/images/gifs/vertex_loader.gif";
import hai_loader from "../../assets/images/gifs/hai_loader.gif";
import servicenow_loader from "../../assets/images/gifs/servicenow_loader.gif";
import store_loader from "../../assets/images/gifs/store_loader.gif";
interface Props {
  answer: AskResponse;
  onCitationClicked: (citedDocument: Citation) => void;
  currentquestion: string;
  selectedindex: string;
  answerindex?: number;
}

export const Answer = ({
  answer,
  currentquestion,
  onCitationClicked,
  selectedindex,
  answerindex,
}: Props) => {
  const facts: string[] = [
    "The term 'Artificial Intelligence' was coined by computer scientist John McCarthy in 1956 during the Dartmouth Conference.",
    "AI is used in various everyday applications, such as virtual assistants like Siri and Alexa, recommendation systems on streaming platforms, and spam filters in email.",
    "Machine Learning, a subset of AI, enables computers to learn from data and improve over time without being explicitly programmed.",
    "Deep Learning, a further subset of Machine Learning, mimics the human brain's neural networks and has led to breakthroughs in areas like computer vision and natural language processing.",
    "AI ethics is a growing concern, with issues such as bias in algorithms, privacy concerns, and the potential for job displacement due to automation.",
    "AI is being increasingly adopted across industries, including healthcare (diagnosis and treatment), finance (fraud detection and trading algorithms), and transportation (autonomous vehicles).",
    "The development of AI is driven by a combination of academic research, industry investment, and government funding in countries around the world.",
    "The field of AI continues to evolve rapidly, with new breakthroughs and applications emerging regularly, shaping the way we live and work.",
    "As AI becomes more integrated into society, understanding its capabilities, limitations, and ethical implications is crucial for responsible deployment and use.",
    "AI has been around since the mid-20th century but has recently gained significant traction due to advancements in computing power and data availability.",
  ];
  const [currentFactIndex, setCurrentFactIndex] = useState(0);
  let selectedImage: string;
  switch (selectedindex) {
    case "Vertex":
      selectedImage = vertex_loader;
      break;
    case "HAI Buddy":
      selectedImage = hai_loader;
      break;
    case "Business Impact":
      selectedImage = servicenow_loader;
      break;
    case "Store Manager":
      selectedImage = store_loader;
      break;
    default:
      selectedImage = vertex_loader; // Default to image1 or provide a fallback
  }
  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     // Move to the next fact
  //     setCurrentFactIndex((prevIndex) =>
  //       prevIndex < facts.length - 1 ? prevIndex + 1 : 0
  //     );
  //   }, 5000);

  //   // Clean up the timer on component unmount
  //   return () => clearInterval(timer);
  // }, [facts.length]);
  const bottomOfPanel = React.useRef<HTMLDivElement>(null);

  const {
    answerStatus,
    setAnswerStatus,
    generatedAns,
    setGeneratedAns,

    isSidebarOpen,
    setSidebarOpen,
  } = useAppContext();
  const [isRefAccordionOpen, { toggle: toggleIsRefAccordionOpen }] =
    useBoolean(false);
  const filePathTruncationLimit = 50;

  const parsedAnswer = useMemo(() => parseAnswer(answer), [answer]);
  const [chevronIsExpanded, setChevronIsExpanded] =
    useState(isRefAccordionOpen);
  useEffect(() => {
    bottomOfPanel.current?.scrollIntoView({ behavior: "smooth" });
  }, [answer, isSidebarOpen]);
  const handleChevronClick = () => {
    setChevronIsExpanded(!chevronIsExpanded);
    toggleIsRefAccordionOpen();
  };

  useEffect(() => {
    setChevronIsExpanded(isRefAccordionOpen);
  }, [isRefAccordionOpen]);

  const createCitationFilepath = (
    citation: Citation,
    index: number,
    truncate: boolean = false
  ) => {
    let citationFilename = "";

    if (citation.filepath && citation.chunk_id) {
      if (truncate && citation?.filepath?.length > filePathTruncationLimit) {
        const citationLength = citation?.filepath?.length;
        citationFilename = `${citation.filepath.substring(
          0,
          20
        )}...${citation?.filepath?.substring(citationLength - 20)} - Part ${parseInt(citation.chunk_id) + 1
          }`;
      } else {
        citationFilename = `${citation?.filepath} - Part ${parseInt(citation?.chunk_id) + 1
          }`;
      }
    } else {
      citationFilename = `Citation ${index}`;
    }
    return citationFilename;
  };
  if (
    parsedAnswer.markdownFormatText === "" ||
    parsedAnswer.markdownFormatText === "Generating answer..."
  ) {
    setAnswerStatus(false);
  } else {
    setAnswerStatus(true);
    setGeneratedAns(parsedAnswer.markdownFormatText);
  }
  return (
    <CRow className="mx-0 fullAnsCont">
      <CCol className={`px-0 ${styles?.answerIcon}`}>
        <span className={styles?.titleIcon}></span>
      </CCol>
      <CCol className={`px-0 ${styles?.answerContainerCol}`} md={11}>
        <Stack className={styles?.answerContainer}>
          <Stack.Item grow className={styles?.ansInnerCnt}>
            {parsedAnswer?.markdownFormatText === "" ||
              parsedAnswer?.markdownFormatText === "Generating answer..." ? (
              <div className={styles?.loadingimgdiv}>
                <div className="typingAnime">
                  <div className="bounce1"></div>
                  <div className="bounce2"></div>
                  <div className="bounce3"></div>
                </div>
              </div>
            ) : (
              <ReactMarkdown
                linkTarget="_blank"
                remarkPlugins={[remarkGfm, supersub]}
                children={parsedAnswer?.markdownFormatText}
                className={`${styles?.answerText} ${"answerText"}`}
              />
            )}
          </Stack.Item>
          {!!parsedAnswer?.citations?.length && (
            <Stack horizontal className={styles?.answerFooter}>

              <Stack.Item aria-label="References">
                <Stack style={{ width: "100%" }}>
                  <Stack
                    horizontal
                    horizontalAlign="start"
                    verticalAlign="center"
                  >
                    <Text
                      className={styles?.accordionTitle}
                      onClick={toggleIsRefAccordionOpen}
                    >
                      <span>
                        {parsedAnswer?.citations?.length > 1
                          ? parsedAnswer?.citations?.length + " references"
                          : "1 reference"}
                      </span>
                    </Text>
                    <FontIcon
                      className={styles?.accordionIcon}
                      onClick={handleChevronClick}
                      iconName={
                        chevronIsExpanded ? "ChevronDown" : "ChevronRight"
                      }
                    />
                  </Stack>
                </Stack>
              </Stack.Item>
              {/* <Stack.Item className={styles.answerDisclaimerContainer}>
            <div>
              <span className={styles.answerDisclaimer}>
                GenAI content may be incorrect
              </span>
            </div>
                </Stack.Item> */}
            </Stack>
          )}
          {chevronIsExpanded && (
            <div
              style={{
                marginTop: 8,
                display: "flex",
                flexFlow: "wrap column",
                maxHeight: "150px",
                gap: "4px",
              }}
            >
              {parsedAnswer?.citations?.map((citation, idx) => {
                return (
                  <span
                    title={createCitationFilepath(citation, ++idx)}
                    key={idx}
                    onClick={() => onCitationClicked(citation)}
                    className={styles?.citationContainer}
                  >
                    <div className={styles?.citation}>{idx}</div>
                    {createCitationFilepath(citation, idx, true)}
                  </span>
                );
              })}
            </div>
          )}
        </Stack>
        {/* {parsedAnswer.markdownFormatText === "" ||
          (parsedAnswer.markdownFormatText === "Generating answer..." && (
            <span className={styles.quickFacts}>
              <b>Quick facts :</b>
              {facts[currentFactIndex]}
            </span>
          ))} */}
        {/* {answerStatus && <Recommendation answerindex={answerindex} />} */}
      </CCol>
      <CCol></CCol>
      <div ref={bottomOfPanel}></div>
    </CRow>
  );
};
interface UserInputProps {
  onSend: (question: string) => void;
}
export const UserInput = ({ onSend }: UserInputProps) => {
  const [macAddress, setMacAddress] = useState("");
  const [role, setRole] = useState("");

  const onSendClick = () => {
    console.log("MAC Address:", macAddress);
    console.log("Role:", role);
    // Call the parent component's onSend function and pass the MAC Address and Role values
    onSend(`MAC: ${macAddress} ROLE: ${role}`);
  };

  const onResetClick = () => {
    // Clear the input fields
    setMacAddress("");
    setRole("");
  };

  return (
    <CRow className="mx-0 fullAnsCont">
      <CCol className={`px-0 ${styles?.answerIcon}`}>
        <span className={styles?.titleIcon}></span>
      </CCol>
      <CCol className={`px-0 ${styles?.answerContainerCol}`} md={11}>
        <div className={styles?.MacAddressForm}>
          <div className={styles?.MacAddressInnerDiv}>
            <span className={styles.formheading}>
              Do you need to Register your New Device in ClearPass?
            </span>
            <CRow className="mb-3">
              <CFormLabel
                htmlFor="macaddress"
                className="col-sm-3 col-form-label"
              >
                MAC Address
              </CFormLabel>
              <CCol sm={9}>
                <CFormInput
                  type="text"
                  id="macaddress"
                  value={macAddress}
                  onChange={(e) => setMacAddress(e.target.value)}
                />
              </CCol>
            </CRow>
            <CRow className="mb-3">
              <CFormLabel
                htmlFor="role"
                className="col-sm-3 col-form-label"
              >
                Role
              </CFormLabel>
              <CCol sm={9}>
                <CFormInput
                  type="text"
                  id="role"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                />
              </CCol>
            </CRow>
            <CRow className="mb-3">
              <CCol sm={3}></CCol>
              <CCol sm={9}>
                <CButton
                  className={styles?.submitbutton}
                  color="Primary"
                  onClick={onSendClick}
                >
                  Send
                </CButton>
                <CButton
                  className={styles?.submitbutton}
                  color="Primary"
                  onClick={onResetClick}
                >
                  Reset
                </CButton>
              </CCol>
            </CRow>
          </div>
        </div>
      </CCol>
      <CCol></CCol>
    </CRow>
  );
};
