export type AskResponse = {
  answer: string;
  citations: Citation[];
  error?: string;
};

export type Citation = {
  content: string;
  id: string;
  title: string | null;
  filepath: string | null;
  url: string | null;
  metadata: string | null;
  chunk_id: string | null;
  reindex_id: string | null;
};

export type ToolMessageContent = {
  citations: Citation[];
  intent: string;
};

export type ChatMessage = {
  role: string;
  content: string;
  end_turn?: boolean;
  callback?: Function;
  noAnsCallback?: Function;
  email?: string;
  reward?: number;
  penalty?: number;
};

export enum ChatCompletionType {
  ChatCompletion = "chat.completion",
  ChatCompletionChunk = "chat.completion.chunk",
}

export type ChatResponseChoice = {
  messages: ChatMessage[];
};

export type ChatResponse = {
  id: string;
  model: string;
  created: number;
  object: ChatCompletionType;
  choices: ChatResponseChoice[];
  references: string;
};

export type ConversationRequest = {
  messages: ChatMessage[];
};

export type UserInfo = {
  access_token: string;
  expires_on: string;
  id_token: string;
  provider_name: string;
  user_claims: any[];
  user_id: string;
};
