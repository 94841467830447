import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./StoreScannerSystems.module.css";
import { Typography, Checkbox, Divider, Spin, Table } from "antd";
import { Col, Row } from "antd";
import CustomTable from "../../components/CommonComponents/Table";
import Header from "../../components/CommonComponents/Header";
import type { GetProp } from "antd";
import {
  environmentData,
  EnvironmentData,
  EnvironmentConfig,
} from "../../environments/env";
import { GetAccessToken } from "../../services/AuthToken";
import "./StoreScannerSystems.module.css"
import PieChart from "../../components/PieChart/PieChart";


const { Title } = Typography;
type CheckboxValueType = GetProp<typeof Checkbox.Group, "value">[any];

const CheckboxGroup = Checkbox.Group;
const plainOptions = [
  { label: <span className="check-handm-icon"></span>, value: "HM" },
  { label: <span className="check-cos-icon"></span>, value: "COS" },
  { label: <span className="check-weekday-icon"></span>, value: "WKD" },
  { label: <span className="check-monkl-icon"></span>, value: "MON" },
  {
    label: <span className="check-otherstories-icon"></span>,
    value: "OS",
  },
  { label: <span className="check-arket-icon"></span>, value: "ARK" },
];

const StoreScannerSystems = () => {
  let runEnv: keyof EnvironmentData =
    environmentData.run as keyof EnvironmentData;
  const environmentConfig: EnvironmentConfig = environmentData[
    runEnv
  ] as EnvironmentConfig;
  const [checkedBrandsList, setCheckedBrandList] = useState<
    CheckboxValueType[]
  >([]);
  const [storeScannerData, setStoreScannerData] = useState([]);
  const [storeScannerFilteredData, setStoreScannerFilteredData] = useState([]);
  const [storeScannerFilteredSyncData, setStoreScannerFilteredSyncData] = useState([]);
  const [storeSyncStatRawChart, setStoreSyncStatRawChart] = useState<any>([]);
  const [storeSyncStatChart, setStoreSyncStatChart] = useState<any>([]);
  const [storeSyncFilteredStatChart, setStoreSyncFilteredStatChart] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [storesNotSyncedLoading, setStoresNotSyncedLoading] = useState(false);
  const [storeSyncData, setStoreSyncData] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState<any>([]);

  const navigate = useNavigate();

  const location = useLocation()

  const handleFilterData = (brands: any) => {
    const filteredRecords = storeScannerData?.filter((val: any) => {
      return brands?.includes(val?.brandCode)
    })
    setStoreScannerFilteredData(filteredRecords)
  }

  const handleFilterSyncData = (brands: any) => {
    const filteredStoreNotSyncRecords = storeSyncData?.filter((val: any) => {
      return brands?.includes(val?.brandCode)
    })
    setStoreScannerFilteredSyncData(filteredStoreNotSyncRecords)
  }

  const handleFilterChart = (brands: any) => {
    let synced = 0, notSynced = 0, percentage_NotSynced = 0, percentage_Synced = 0, totalStores = 0
    for (const val of storeSyncStatRawChart) {
      if (brands?.includes(val?.brandCode)) {
        synced += val?.synced
        notSynced += val?.notSynced
        totalStores += val?.totalStores
      }

    }
    percentage_NotSynced = (notSynced / totalStores) * 100;
    percentage_Synced = (synced / totalStores) * 100;

    setStoreSyncFilteredStatChart({ totalStores, synced, notSynced, percentage_Synced: percentage_Synced?.toFixed(2), percentage_NotSynced: percentage_NotSynced?.toFixed(2) })
  }

  const onCheckBoxChange = async (list: CheckboxValueType[]) => {
    setSelectedBrands(list)
    const params = new URLSearchParams();

    // if(selectedBrands){
    params.set(
      "brands",
      list?.toString()
    );

    navigate({ search: params.toString() }, { replace: true });

    if (list.length === 0) {
      setCheckedBrandList(list);
      await fetchStoreScannerReport({}, true)
      await fetchStoreSyncStatusReport(true)
      // setStoreScannerFilteredData(storeScannerData)
      // setStoreScannerFilteredSyncData(storeSyncData)
      // setStoreSyncFilteredStatChart(storeSyncStatChart)
    } else {
      setCheckedBrandList(list);
      handleFilterData(list)
      handleFilterSyncData(list)
      handleFilterChart(list)
    }
  };
  const columns = [
    {
      title: "COUNTRY",
      dataIndex: "countryName",
      key: "countryName",
      width: 200,
      onHeaderCell: () => ({
        // style: { textAlign: 'center' as 'center' },
      }),
      render: (text: any) => <a >{text}</a>,
    },
    {
      title: "Brand",
      dataIndex: "brandName",
      key: "brandName",
      width: 200,
      onHeaderCell: () => ({
        // style: { textAlign: 'center' as 'center' },
      }),
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: "PRICE UPDATE",
      dataIndex: "priceUpdateDifferenceHrs",
      key: "priceUpdateDifferenceHrs",
      width: 240,
      sorter: (a: any, b: any) => a.priceUpdateDifferenceHrs - b.priceUpdateDifferenceHrs,
      onHeaderCell: () => ({
        // style: { textAlign: 'center' as 'center' },
      }),

      render: (text: any, item: any) => (
        <a
          style={{
            color: item?.color_Price === "Yellow" ? "#FB8C00" : item.color_Price,
          }}
        >
          {text} Hrs ago
        </a>
      ),
    },
    {
      title: "ITEM",
      dataIndex: "itemUpdateDifferenceDays",
      key: "itemUpdateDifferenceDays",
      width: 200,
      sorter: (a: any, b: any) => a.itemUpdateDifferenceDays - b.itemUpdateDifferenceDays,
      onHeaderCell: () => ({
        // style: { textAlign: 'center' as 'center' },
      }),
      render: (text: any, item: any) => (
        <a
          style={{
            color: item?.color_Item === "Yellow" ? "#FB8C00" : item?.color_Item,
          }}
        >
          {text} days ago
        </a>
      ),
    },
    {
      title: "STORE INVENTORY COUNT ",
      dataIndex: "inventoryUpdateDifferenceHrs",
      key: "inventoryUpdateDifferenceHrs",
      width: 250,
      sorter: (a: any, b: any) => a.inventoryUpdateDifferenceHrs - b.inventoryUpdateDifferenceHrs,
      onHeaderCell: () => ({
        // style: { textAlign: 'center' as 'center' },
      }),
      render: (text: any, item: any) => (
        <a
          style={{
            color: item?.color_Inventory === "Yellow" ? "#FB8C00" : item?.color_Inventory,
          }}
        >
          {text} Hrs ago
        </a>
      ),
    },
  ];

  const chartOptions = {
    cutout: '75%', // Adjust this value to change the width of the circular bar
    plugins: {
      legend: {
        display: false
      },
    }
  } as any;


  const storeNotSyncedCol = [
    {
      title: "STORE ID",
      dataIndex: "locationGroupName",
      key: "locationGroupName",
      width: 100,
      onHeaderCell: () => ({
        // style: { textAlign: 'center' as 'center' },
      }),
      sorter: (a: any, b: any) => a.locationGroupName.localeCompare(b.locationGroupName),
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: "LOCATION",
      dataIndex: "popularName",
      key: "popularName",
      width: 200,
      onHeaderCell: () => ({
        // style: { textAlign: 'center' as 'center' },
      }),
      render: (text: any) => <a>{text}</a>,
    }
  ]

  const fetchStoreScannerReport = async (params: any, getAll: boolean) => {
    setLoading(true)
    let token = await GetAccessToken();
    try {
      const response = await fetch(
        `${environmentConfig.chatApiUrl}/StoreScanner/GetStoreScannerReport`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(params),
        }
      );
      if (!response.ok) {
        setLoading(false)
        throw new Error("Network response was not ok");
      }
      const data: any = await response.json();
      setStoreScannerData(data);
      const urlParams = new URLSearchParams(location.search);
      const brands: any =
        (urlParams.get("brands") && urlParams.get("brands")?.split(",")) || [];
      if (brands?.length > 0 && !getAll) {
        const filteredRecords = data?.filter((val: any) => {
          return brands?.includes(val?.brandCode)
        })
        setStoreScannerFilteredData(filteredRecords)
        // handleFilterData(brands)
      } else {
        setStoreScannerFilteredData(data);
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error(
        "Error while fetching Customer Counter reports data:",
        error
      );
    }
  };

  // useEffect(() => {
  //   const params = {
  //     storeId: [],
  //     brands: checkedBrandsList,
  //     countries: [],
  //   };
  //   fetchStoreScannerReport(params);
  // }, [checkedBrandsList]);

  const fetchStoreSyncStatusReport = async (getAll: boolean) => {
    setStoresNotSyncedLoading(true)
    let token = await GetAccessToken();
    try {
      const response = await fetch(
        `${environmentConfig.chatApiUrl}/StoreScanner/GetStoreSyncStatus
 `,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          // body: JSON.stringify(params),
        }
      );
      if (!response.ok) {
        setStoresNotSyncedLoading(false)
        throw new Error("Network response was not ok");
      }
      const data: any = await response.json();
      setStoreSyncData(data?.nonSyncedStores);
      setStoreSyncStatRawChart(data?.storeSyncStat)


      const urlParams = new URLSearchParams(location.search);
      const brands: any =
        (urlParams.get("brands") && urlParams.get("brands")?.split(",")) || [];
      if (brands?.length > 0 && !getAll) {
        const filteredStoreNotSyncRecords = data?.nonSyncedStores?.filter((val: any) => {
          return brands?.includes(val?.brandCode)
        })
        setStoreScannerFilteredSyncData(filteredStoreNotSyncRecords)

        // filtering chart
        let synced = 0, notSynced = 0, percentage_NotSynced = 0, percentage_Synced = 0, totalStores = 0
        for (const val of data?.storeSyncStat) {
          if (brands?.includes(val?.brandCode)) {
            synced += val?.synced
            notSynced += val?.notSynced
            totalStores += val?.totalStores
          }
    
        }
        percentage_NotSynced = (notSynced / totalStores) * 100;
        percentage_Synced = (synced / totalStores) * 100;
    
        setStoreSyncFilteredStatChart({ totalStores, synced, notSynced, percentage_Synced: percentage_Synced?.toFixed(2), percentage_NotSynced: percentage_NotSynced?.toFixed(2) })
      

      } else {
        setStoreScannerFilteredSyncData(data?.nonSyncedStores);
        handleTotalStoreSyncChartData(data?.storeSyncStat)
      }

      setStoresNotSyncedLoading(false)
    } catch (error) {
      setStoresNotSyncedLoading(false)
      console.error(
        "Error while fetching Customer Counter reports data:",
        error
      );
    }
  };

  const handleTotalStoreSyncChartData = (data: any[]) => {
    let synced = 0, notSynced = 0, percentage_NotSynced = 0, percentage_Synced = 0, totalStores = 0
    for (const val of data) {
      synced += val?.synced
      notSynced += val?.notSynced
      totalStores += val?.totalStores
    }
    percentage_NotSynced = (notSynced / totalStores) * 100;
    percentage_Synced = (synced / totalStores) * 100;

    setStoreSyncStatChart({ totalStores, synced, notSynced, percentage_Synced: percentage_Synced?.toFixed(2), percentage_NotSynced: percentage_NotSynced?.toFixed(2) })
    setStoreSyncFilteredStatChart({ totalStores, synced, notSynced, percentage_Synced: percentage_Synced?.toFixed(2), percentage_NotSynced: percentage_NotSynced?.toFixed(2) })
  }


  useEffect(() => {

    const urlParams = new URLSearchParams(location.search);
    const brands: any =
      (urlParams.get("brands") && urlParams.get("brands")?.split(",")) || [];
    setSelectedBrands(brands)
    setCheckedBrandList(brands)
    const params = {

    };
    fetchStoreScannerReport(params, false);
    fetchStoreSyncStatusReport(false)
    handleFilterData(selectedBrands)

  }, []);


  return (
    <div>
      <Header pageTitle="Business Command Center" />
      <div className={styles.mainDiv}>
        <div className={styles.routePageTitleWrapper}>
          <a
            className={styles.breadCrumbsLink}
            style={{ paddingLeft: "0px" }}
            onClick={() => navigate("/")}
          >
            Home
          </a>
          <a>&gt;</a>
          <a
            className={styles.breadCrumbsLink}
            onClick={() => navigate("/applicationhealth")}
          >
            Application Health
          </a>
          <a>&gt;</a>
          <a
            className={styles.breadCrumbsLink}
            style={{ textDecoration: "underline", cursor: "auto" }}
          >
            Store Scanner systems(MSS services)
          </a>
        </div>
        <div className={styles.headerDiv} >
          {/* <Col span={24}> */}
          <div className={styles.heading}>
            <h2 className={styles.storeScannerTitle}>
              Store Scanner systems (MSS services)
            </h2>
          </div>
          {/* </Col> */}
          <div className={styles.checkboxWrapper}>
            <div className={styles.filterTitle}>Filter by Brands:</div>
            <CheckboxGroup
              className={styles.antCheckboxGroup + " custom-checkbox"}
              options={plainOptions}
              value={checkedBrandsList}
              onChange={onCheckBoxChange}
            />
          </div>
        </div>
        <Divider
          style={{
            marginTop: "12px",
            marginBottom: "30px",
            height: "1px",
            backgroundColor: "#cccccc",
          }}
        ></Divider>

        <div className={styles.contentMainDiv}>
          <div className={styles.tablesContainer}>
            <div className={styles.smartphonesTableContainer} style={{ width: "100%" }}>
              <div className={styles.divisionHeading} >MSS to Smartphones</div>
              {loading ? (
                <div className={styles.spinnerContainer}>
                  <Spin size="large" />
                </div>
              ) : (
                <CustomTable columns={columns} data={storeScannerFilteredData} />
              )}
            </div>
          </div>
          <div className={styles.secondHalfContainer}>
            <div className={styles.storesNotSyncedtableContainer} style={{ marginBottom: "30px" }}>
              <div className={styles.divisionHeading}>Store Not Synced</div>
              {storesNotSyncedLoading ? (
                <div className={styles.spinnerContainer}>
                  <Spin size="large" />
                </div>
              ) : (
                <Table
                  columns={storeNotSyncedCol}
                  dataSource={storeScannerFilteredSyncData}
                  className="notSync-table "
                  style={{
                    border: "1px solid #CCCCCC",
                    width: "100vw",
                    overflowX: "scroll",
                    fontSize: "12px",
                    minHeight: "450px"
                  }}
                  // scroll={{ y: 'calc(100vh - 300px)' }}
                  scroll={{ y: "324px" }}
                  tableLayout="fixed"
                />
              )}
            </div>
            <div className={styles.chartHeadingMobile}>Store Device Sync</div>
            <div className={styles.chartDiv} style={{ marginBottom: "30px" }}>
              <div className={styles.chartHeading}>Store Device Sync</div>
              {storesNotSyncedLoading ? (
                <div className={styles.spinnerContainer}>
                  <Spin size="large" />
                </div>
              ) : (
                <div className={styles.chartContainer} style={{ position: "relative", border: "1px solid #bdbaba" }}>


                  <div style={{ position: "relative", marginTop: "30px", display: "flex", flexFlow: "column", alignItems: "center" }}>
                    <PieChart chartData={[["% of stores with at least one device synced to network BDD", storeSyncFilteredStatChart?.synced], ["not synced", storeSyncFilteredStatChart?.notSynced]]} chartColors={['#066B34', '#C82D0F']} />
                    <div className={styles.redLabel} >{storeSyncFilteredStatChart?.percentage_NotSynced}%</div>
                    <div className={styles.greenLabel}>{storeSyncFilteredStatChart?.percentage_Synced}%</div>

                    <div style={{ position: "absolute", top: "", color: "#000000", fontSize: "18px" }}>Total stores : {storeSyncFilteredStatChart?.totalStores}</div>
                  </div>
                  <div style={{ marginTop: "" }}>
                    <div style={{ display: "flex", marginLeft: "20px" }}> <div style={{ width: "20px", height: "20px", backgroundColor: "#066B34", marginRight: "8px", marginBottom: "10px" }}></div><p style={{ color: "#555555" }}>% of stores with at least one device synced to network BDD</p></div>
                    <div style={{ display: "flex", marginLeft: "20px" }}> <div style={{ width: "20px", height: "20px", backgroundColor: "#C82D0F", marginRight: "8px" }}></div><p style={{ color: "#555555" }}>Not Synced</p></div>
                  </div>
                </div>
              )}

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StoreScannerSystems;