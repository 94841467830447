import React, { useEffect, useState } from "react";
import styles from "./Home.module.css";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CImage,
  CRow,
  CTooltip,
} from "@coreui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import myImage from "../../assets/images/HomeBackground.png";
import AppHeaderMobile from "../../components/AppHeaderMobile";
import { GetUserName } from "../../services/AuthUser";
import { Button, Modal, Checkbox, Select, Tooltip } from "antd";
import type { GetProp } from "antd";
import countries from "../../data/countries.json";
import stores from "../../data/stores.json";
import DropDownList from "../../components/CommonComponents/DropDownList";
import { useAppContext } from "../../components/ContextAndProvider/Context";
import {
  environmentData,
  EnvironmentData,
  EnvironmentConfig,
} from "../../environments/env";
import { GetAccessToken } from "../../services/AuthToken";
import TotalCountsCard from "../../components/CommonComponents/TotalCountsCard";
// import modalItems from "../../data/modalItems.json";
type Metric = {
  id: string;
  name: string;
  status: string;
};

type Category = {
  Name: string;
  DisplayName: string;
  Items: Metric[];
};

const Store = () => {
  let runEnv: keyof EnvironmentData =
    environmentData.run as keyof EnvironmentData;
  const environmentConfig: EnvironmentConfig = environmentData[
    runEnv
  ] as EnvironmentConfig;
  // Context provide
  const { open360Modal, setOpen360Modal } = useAppContext();

  const handleLogin = (loginType: "popup" | "redirect"): void => {};
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalItems, setModalItems] = useState<Category[]>([]);
  const [storeDashboardStats, setStoreDashboardStats] = useState<any>([]);
  const onChange: GetProp<typeof Checkbox.Group, "onChange"> = (
    checkedValues
  ) => {
    // console.log("checked = ", chec.kedValues);
  };

  const [closedApps, setClosedApps] = useState<string[]>([]);

  const handleCloseApp = (appName: string) => {
    setClosedApps([...closedApps, appName]);
  };

  const plainOptions = [
    { label: <span className="check-handm-icon"></span>, value: "H&m" },
    { label: <span className="check-cos-icon"></span>, value: "COS" },
    { label: <span className="check-weekday-icon"></span>, value: "WEEKDAY" },
    { label: <span className="check-monkl-icon"></span>, value: "MONKL" },
    {
      label: <span className="check-otherstories-icon"></span>,
      value: "Other Stories",
    },
    { label: <span className="check-arket-icon"></span>, value: "ARKET" },
  ];
  const navigate = useNavigate();

  const handleButtonClick = () => {
    // Redirect to /chat
    navigate("/reports");
  };
  const handleNetworkClick = () => {
    // Redirect to /chat
    navigate("/network");
  };
  const onStoreNotificationClick = () => {
    // Redirect to /chat
    navigate("/storenotification");
  };
  const onApplicationHealthClick = () => {
    // Redirect to /chat
    navigate("/applicationhealth");
  };
  const handleMetricsListClick = () => {
    // Redirect to /metrics list
    navigate("/metricslist");
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleCountryChange = (value: string) => {
  };
  const handleStoreChange = (value: string) => {
  };
  useEffect(() => {
    const fetchData = async () => {
      let token = await GetAccessToken();
      try {
        const response = await fetch(
          `${environmentConfig.chatApiUrl}/StoreReport/GetFullStoreView`,
          {
            method: "POST",
            headers: {
              Accept: "text/plain",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              reportDate: new Date().toISOString(),
              storeId: ["string"],
              brands: ["string"],
              countries: ["string"],
            }),
          }
        );

        const result = await response.json();
        const transformedData = transformResponse(result);
        setModalItems(transformedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const location = useLocation();
  useEffect(() => {
    if (open360Modal) {
      showModal();
      setOpen360Modal(false);
    }
  }, []);

  useEffect(() => {
    const fetchStoresDashboardStatsData = async () => {
      let token = await GetAccessToken();
      try {
        const response = await fetch(
          `${environmentConfig.chatApiUrl}/StoreReport/GetStoreDashboardStats`,
          {
            method: "GET",
            headers: {
              Accept: "text/plain",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const result = await response.json();
        setStoreDashboardStats(result?.[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchStoresDashboardStatsData();
  }, []);

  const transformResponse = (response: any): Category[] => {
    return [
      {
        Name: "Application",
        DisplayName: "APPLICATION",
        Items: response.applicationMetrics.map((item: Metric) => ({
          Name: item.name,
          Status: item.status,
        })),
      },
      {
        Name: "Hardware",
        DisplayName: "HARDWARE",
        Items: response.hardwareMetrics.map((item: Metric) => ({
          Name: item.name,
          Status: item.status,
        })),
      },
      {
        Name: "Report",
        DisplayName: "REPORT",
        Items: response.reportMetrics.map((item: Metric) => ({
          Name: item.name,
          Status: item.status,
        })),
      },
      {
        Name: "Stock Management",
        DisplayName: "STOCK MANAGEMENT",
        Items: response.stockMetrics.map((item: Metric) => ({
          Name: item.name,
          Status: item.status,
        })),
      },
      {
        Name: "Network",
        DisplayName: "NETWORK",
        Items: response.networkMetrics.map((item: Metric) => ({
          Name: item.name,
          Status: item.status,
        })),
      },
      {
        Name: "Sales Management",
        DisplayName: "SALES MANAGEMENT",
        Items: response.salesMetrics.map((item: Metric) => ({
          Name: item.name,
          Status: item.status,
        })),
      },
    ];
  };
  const StoresList = [
    { value: "SE0178", key: "SE0178", checked: false },
    { value: "SE0180", key: "SE0180", checked: false },
    { value: "GB0312", key: "GB0312", checked: false },
    { value: "GB0660", key: "GB0660", checked: false },
    { value: "SE0144", key: "SE0144", checked: false },
    { value: "IT2982379", key: "IT2982379", checked: false },
    { value: "IT29212345", key: "IT29212345", checked: false },
    { value: "KH2982309", key: "KH2982309", checked: false },
    { value: "KK2982356", key: "KK2982356", checked: false },
    { value: "UY29823799", key: "UY29823799", checked: false },
  ];
  const CountryList = [
    { value: "Sweden", key: "SWE", checked: false },
    { value: "United States", key: "US", checked: false },
  ];
  const handleCountryOnchange = (updatedItems: any[]) => {
  };
  const handleCheckboxChange = (updatedItems: any[]) => {
  };

  const openApplication = (appName: any) => {
    if (appName === "Store Notification") {
      navigate("/storenotification");
    }
    if (appName === "Application Health") {
      navigate("/applicationhealth");
    }
  };

  const [showReportTooltip, setShowReportTooltip] = useState(false);
  const [showHardwareTooltip, setShowHardwareTooltip] = useState(false);
  const [showAppsTooltip, setShowAppsTooltip] = useState(false);
  const [show360Tooltip, setShow360Tooltip] = useState(false);

  useEffect(()=>{
    const url = localStorage.getItem("redirectingUrl")
    if(url){
      localStorage.removeItem("redirectingUrl")
      navigate(url)
    }
  },[])

  return (
    <div className={styles.homecontainer}>
      <AppHeaderMobile userDetails={GetUserName()} />
      <div className={styles.divider} />

      <div className={styles.homepage}>
        <Link className={styles.feedbackLinkStyle} to="/feedback">
          FEEDBACK
        </Link>
        <Link className={styles.feedbackLinkStyleForMobile} to="/feedback">
          FEEDBACK
        </Link>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>
            <div className={styles?.bigcircle}>
              <div
                className={styles.reportTooltip}
                hidden={!showReportTooltip}
                style={{
                  position: "absolute",
                  border: "1px solid black",
                  top: "17px",
                  right: "331px",
                  backgroundColor: "white",
                  padding: "3px 5px",
                  borderRadius: "5px",
                  zIndex: "10",
                  paddingLeft: "6px",
                }}
                onMouseEnter={() => {
                  setShowReportTooltip(true);
                }}
                onMouseLeave={() => {
                  setShowReportTooltip(false);
                }}
              >
                You will be redirected to reports
              </div>
              <div
                className={styles.hardwareTooltip}
                hidden={!showHardwareTooltip}
                style={{
                  position: "absolute",
                  border: "1px solid black",
                  top: "113px",
                  right: "358px",
                  backgroundColor: "white",
                  padding: "3px 5px",
                  borderRadius: "5px",
                  zIndex: "10",
                  paddingLeft: "6px",
                }}
                onMouseEnter={() => {
                  setShowHardwareTooltip(true);
                }}
                onMouseLeave={() => {
                  setShowHardwareTooltip(false);
                }}
              >
                You will be redirected to hardware
              </div>
              <div
                className={styles.appsTooltip}
                hidden={!showAppsTooltip}
                style={{
                  position: "absolute",
                  border: "1px solid black",
                  bottom: "11px",
                  left: "336px",
                  backgroundColor: "white",
                  padding: "3px 5px",
                  borderRadius: "5px",
                  zIndex: "10",
                }}
                onMouseEnter={() => {
                  setShowAppsTooltip(true);
                }}
                onMouseLeave={() => {
                  setShowAppsTooltip(false);
                }}
              >
                You will be redirected to apps
              </div>
              <div className={styles?.innerImageCircle}>
                <div className={styles?.colordiv + " stockmanagement"}>
                  <span className={styles.redRound}></span>
                  <p className="disabled">STOCK MANAGEMENT</p>
                  <div
                    className={styles.stockmanagementicon + " disabled"}
                  ></div>
                </div>

                <div
                  className={styles?.colordiv + " reports"}
                  onClick={handleButtonClick}
                  onMouseEnter={() => {
                    setShowReportTooltip(true);
                  }}
                  onMouseLeave={() => {
                    setShowReportTooltip(false);
                  }}
                >
                  <span className={styles.redRound}></span>
                  <p>REPORTS</p>
                  <div className={styles.reportsicon}></div>
                </div>
                <div
                  className={styles?.colordiv + " hardware"}
                  onMouseEnter={() => {
                    setShowHardwareTooltip(true);
                  }}
                  onMouseLeave={() => {
                    setShowHardwareTooltip(false);
                  }}
                >
                  <span className={styles.redRound}></span>
                  <p>HARDWARE</p>
                  <div className={styles.hardwareicon}></div>
                </div>
                <div className={styles?.colordiv + " salesmanagement"}>
                  <span className={styles.redRound}></span>
                  <p className="disabled">SALES MANAGEMENT</p>
                  <div
                    className={styles.salesmanagementicon + " disabled"}
                  ></div>
                </div>
                <div
                  className={styles?.colordiv + " apps"}
                  onMouseEnter={() => {
                    setShowAppsTooltip(true);
                  }}
                  onMouseLeave={() => {
                    setShowAppsTooltip(false);
                  }}
                >
                  <span className={styles.redRound}></span>
                  <p>APPS</p>
                  <div className={styles.appsicon}></div>
                </div>
                <div
                  className={styles?.colordiv + " network"}
                  onClick={handleNetworkClick}
                >
                  <span className={styles.redRound}></span>
                  <p className="disabled">NETWORK</p>
                  <div className={styles.networkicon + " disabled"}></div>
                </div>
                {/* <div className={styles?.colordiv + " orange"}></div>
          <div className={styles?.colordiv + " yellow"}></div>
          <div className={styles?.colordiv + " green"}></div>
          <div className={styles?.colordiv + " blue"}></div>
          <div className={styles?.colordiv + " purple"}></div> */}
                <div
                  className={styles?.smallcircle}
                  onClick={showModal}
                  onMouseEnter={() => {
                    setShow360Tooltip(true);
                  }}
                  onMouseLeave={() => {
                    setShow360Tooltip(false);
                  }}
                >
                  <div
                    hidden={!show360Tooltip}
                    className={styles.store360Tooltip}
                    style={{
                      position: "absolute",
                      border: "1px solid black",
                      top: "10px",
                      backgroundColor: "white",
                      padding: "3px 5px",
                      borderRadius: "5px",
                      zIndex: "10",
                    }}
                    onMouseEnter={() => {
                      setShow360Tooltip(true);
                    }}
                    onMouseLeave={() => {
                      setShow360Tooltip(false);
                    }}
                  >
                    You will be redirected to store 360
                  </div>
                  <span className={styles.InnerCircleIcon}></span>
                  <p className={styles.InnerCirclePara}>STORE</p>
                  <span className={styles.InnerCircle360Icon}></span>
                </div>
              </div>
            </div>
            <div className={styles.favourites}>
              <span className={styles.staricon} hidden></span>
              <div className={styles.allFavouritesItem}>
                <div
                  className={styles.favouritesItem}
                  hidden={closedApps.includes("Store Notification")}
                >
                  <div className={styles.tooltip}>
                    <span className={styles.tooltiptext}>
                      You will be redirected to store notification
                    </span>
                    <span
                      className={styles.closeIcon}
                      onClick={() => {
                        handleCloseApp("Store Notification");
                      }}
                      hidden
                    ></span>
                    <div
                      onClick={onStoreNotificationClick}
                      style={{ width: "100%", cursor: "pointer" }}
                    >
                      <span className={styles.storeNotificationIcon}></span>
                      <p className={styles.favouritesItemPara}>
                        Store Notification
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className={styles.favouritesItem}
                  hidden={closedApps.includes("Application Health")}
                >
                  <div className={styles.tooltip}>
                    <span className={styles.tooltiptext}>
                      You will be redirected to application health
                    </span>
                    <span
                      className={styles.closeIcon}
                      onClick={() => {
                        handleCloseApp("Application Health");
                      }}
                      hidden
                    ></span>
                    <div
                      onClick={onApplicationHealthClick}
                      style={{ width: "100%", cursor: "pointer" }}
                    >
                      <span className={styles.applicationHealthIcon}></span>
                      <p className={styles.favouritesItemPara}>
                        Application Health
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className={styles.favouritesItem}
                  hidden={closedApps.includes("Critical Alert")}
                >
                  <div className={styles.tooltip}>
                    <span className={styles.tooltiptext}>
                      You will be redirected to critical alert
                    </span>
                    <span
                      className={styles.closeIcon}
                      onClick={() => {
                        handleCloseApp("Critical Alert");
                      }}
                      hidden
                    ></span>
                    <div style={{ width: "100%", cursor: "pointer" }}>
                      <span className={styles.criticalAlertIcon}></span>
                      <p className={styles.favouritesItemPara}>
                        Critical Alert
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <span className={styles.addIcon} hidden></span>
            </div>
          </div>
          <div
            className={styles.storesStats}
            style={{
              flex: 1,
              position: "fixed",
              right: 100,
              alignSelf: "center",
            }}
          >
            <TotalCountsCard
              label="Total Number of Stores"
              count={storeDashboardStats?.totalStores || 0}
            />
            <TotalCountsCard
              label="In Business hours"
              count={storeDashboardStats?.inBusinessHours || 0}
              borderColor="#204A7B"
            />
            <TotalCountsCard
              label="Outside Business hours"
              count={storeDashboardStats?.outBusinessHours || 0}
            />
            <TotalCountsCard
              label="Rebuild Stores"
              count={storeDashboardStats?.rebuildStores || 0}
            />
            <TotalCountsCard
              label="Stores with issues"
              count={
                storeDashboardStats?.storeWithIssues > 0
                  ? storeDashboardStats?.storeWithIssues
                  : "Work In Progress"
              }
            />
          </div>
        </div>
      </div>

      <Modal
        title="Store 360 degree view"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className={styles.threeSixtydegViewModal}
        width="90vw"
      >
        <div className={styles.threeSixtydegViewModalContent}>
          <div className={styles.headerTab}>
            <Checkbox.Group
              className={styles.modalcheckboxes}
              options={plainOptions}
              onChange={onChange}
            />
            <div className={styles.verticalLine}></div>
            <DropDownList
              items={CountryList}
              onSelect={handleCountryOnchange}
              styleName="applicationHealthStyle"
              displayName="Choose Country"
            />
            <DropDownList
              items={StoresList}
              onSelect={handleCheckboxChange}
              styleName="applicationHealthStyle"
            />
            <Button
              className={styles.marketListButton}
              onClick={handleMetricsListClick}
            >
              Metrics List
            </Button>
          </div>

          <div className={styles.searchContent}>
            {modalItems.map((category) => (
              <div key={category.Name} className={styles.modalAppItems}>
                <div className={styles.modalAppItems_Header}>
                  <span
                    className={styles.modalAppItemsIcon_Applications}
                  ></span>
                  {category.DisplayName}
                </div>
                <div className={styles.modalAppItems_Content}>
                  {category.Items.map((item: any) => (
                    <div
                      key={item.Name}
                      style={{ color: "#fff" }}
                      className={styles.modalContentItemName}
                    >
                      <span>{item.Name}</span>
                      <span
                        className={
                          styles[item.Status.toLowerCase() + "Round"] +
                          " " +
                          styles.rightEnd
                        }
                      ></span>
                      <span className={styles.verticalThreeDot}></span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </div>

    // <div className={styles.homecontainer}>
    //   <AppHeaderMobile userDetails={GetUserName()} />
    //   <div className={styles.CircleOuterDiv}>
    //     <div className={styles.InnerCircleDiv}></div>
    //   </div>

    // </div>
  );
};
export default Store;
