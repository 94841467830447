import { Card } from "antd";
import { Warning12Filled } from "@fluentui/react-icons";
import styles from "./CardSection.module.css";

interface Props {
  handleClick?: any;
  item?: any;
  styleName?:string;
}

export const CardSection = ({ handleClick, item,styleName }: Props) => {
  const handleCardClick = (value: any) => {
    handleClick(value);
  };

  return (
    <Card
      className={item?.isDisabled ? styles?.disabledCard : styles?.cardSectionBody}
      onClick={() => handleCardClick(item)}
    >
      <div className={styles?.cardName} ><div >{item?.title}</div></div>
      <div className={styles?.waringIconCountWrapper}>
        <div className={styles?.warningIconWrapper}>
          <Warning12Filled
            className={
              styles?.redWarningIcon
            }
            style={{color:item?.color || "none"}}
            aria-hidden="true"
          />
        </div>
        <div className={styles?.devider}></div>
        <div className={styles?.displayCount}>{item?.count}</div>
      </div>
    </Card>
  );
};
