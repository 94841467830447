import { Link } from "react-router-dom";

const NoPage = () => {
    return <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f8f9fa',
        color: '#343a40',
        fontFamily: 'Arial, sans-serif'
    }}>
        <h1 style={{ fontSize: '6em', margin: 0 }}>404</h1>
        <p style={{ fontSize: '1.5em' }}>Oops! The page you're looking for doesn't exist.</p>
        <Link to="/" style={{
            color: '#007bff',
            textDecoration: 'none',
            fontSize: '1.2em'
        }}>
            Go back to Home
        </Link>
    </div>
};

export default NoPage

