import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../config/MsalConfig";

export const LoginFunctions = () => {
  const { instance } = useMsal();

  const handleLogin = (loginType: "popup" | "redirect"): void => {
    if (loginType === "popup") {
      instance.loginPopup(loginRequest);
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest);
    }
  };

  return { handleLogin };
};
