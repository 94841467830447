import { msalInstance } from "../index.tsx";
import timezoneToCountry from "../data/timezone.js"
const GetRoles = () => {
  let roles = [];
  const activeAccount = msalInstance.getActiveAccount();
  if (activeAccount) {
    roles = activeAccount?.idTokenClaims?.roles;
  }
  // console.log("roles", roles);
  return roles ? roles : [];
};
function getCountryByTimezone(timezone) {
  return timezoneToCountry[timezone] || "Unknown country";
}

const GetLoggedInCountry = () =>{
// Get the client's timezone
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

// Look up the country based on the timezone
const country = getCountryByTimezone(timezone);
  return country
}
const GetUserName = () => {
  let userDetails = [];
  let userName = sessionStorage.getItem("userSessionName");
  let userEmail = sessionStorage.getItem("userSessionEmail");
  if (!userName && !userEmail) {
    userName = localStorage.getItem("userSessionName");
    userEmail = localStorage.getItem("userSessionEmail");
    sessionStorage.setItem("userSessionName", userName);
    sessionStorage.setItem("userSessionEmail", userEmail);
  }
  if (userName === "null" || userEmail === "null") {
    const activeAccount = msalInstance.getActiveAccount();
    if (activeAccount) {
      sessionStorage.setItem("userSessionName", activeAccount.name);
      localStorage.setItem("userSessionName", activeAccount.name);
      sessionStorage.setItem("userSessionEmail", activeAccount.username);
      localStorage.setItem("userSessionEmail", activeAccount.username);
      userName = activeAccount.name;
      userEmail = activeAccount.username;
    }
  }
  userDetails = [
    {
      userName: userName,
      userEmail: userEmail,
    },
  ];
  return userDetails;
};

export { GetUserName, GetRoles,GetLoggedInCountry };
