import styles from "./ReportDashboard.module.css";
import Header from "../../components/CommonComponents/Header";
import Reports from "./Reports";

const ReportDashboard = () => {
  return (
    <div>
      <Header pageTitle="Global Visualizer" />
      <div>
        <Reports />
      </div>
    </div>
  );
};
export default ReportDashboard;
