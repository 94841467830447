import React, { useEffect, useState } from "react";
import styles from "./ApplicationHealth.module.css";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CImage,
  CRow,
} from "@coreui/react";
import { useNavigate } from "react-router-dom";
import AppHeaderMobile from "../../components/AppHeaderMobile";
import { GetUserName } from "../../services/AuthUser";
import {
  Button,
  Modal,
  Checkbox,
  Select,
  Breadcrumb,
  Divider,
  Row,
  Col,
  DatePicker,
  Space,
  DatePickerProps,
  Input,
  Collapse,
  Popover,
  Flex,
  Spin,
} from "antd";
import type { CheckboxProps, CollapseProps, GetProp, SelectProps } from "antd";
import AppHeader from "../../components/AppHeader";
import stores from "../../data/stores.json";
import countries from "../../data/countries.json";
import TextArea from "antd/es/input/TextArea";
import { CardSection } from "../../components/CommonComponents/CardSection";
import { CardSectionNew } from "../../components/CommonComponents/CardSectionNew";
import Header from "../../components/CommonComponents/Header";
import {
  environmentData,
  EnvironmentData,
  EnvironmentConfig,
} from "../../environments/env";
import { GetAccessToken } from "../../services/AuthToken";
import { DownOutlined } from "@ant-design/icons";
import "./ApplicationHealth.module.css"

type CheckboxValueType = GetProp<typeof Checkbox.Group, "value">[number];
const { Option: AntOption } = Select; // Renaming 'Option' to 'AntOption'

interface OptionType {
  value: string;
  label: string;
}
const CheckboxGroup = Checkbox.Group;

const plainOptions = [
  { label: <span className="check-handm-icon"></span>, value: "H&m" },
  { label: <span className="check-cos-icon"></span>, value: "COS" },
  { label: <span className="check-weekday-icon"></span>, value: "WEEKDAY" },
  { label: <span className="check-monkl-icon"></span>, value: "MONKL" },
  {
    label: <span className="check-otherstories-icon"></span>,
    value: "Other Stories",
  },
  { label: <span className="check-arket-icon"></span>, value: "ARKET" },
];
const defaultCheckedList = ["Apple", "Orange"];
const { Option } = Select;

const ApplicationHealth = () => {
  let runEnv: keyof EnvironmentData =
    environmentData.run as keyof EnvironmentData;
  const environmentConfig: EnvironmentConfig = environmentData[
    runEnv
  ] as EnvironmentConfig;
  const [checkedList, setCheckedList] =
    useState<CheckboxValueType[]>(defaultCheckedList);
  const [applicationOptions, setApplicationOptions] = useState([]);
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [applicationsToAdd, setApplicationsToAdd] = useState<any>([]);
  const [applicationsToAddDisplay, setApplicationsToAddDisplay] = useState<any>([]);


  const navigate = useNavigate();
  useEffect(() => {
    const fetchApplicationLookup = async () => {
      let token = await GetAccessToken();
      setLoading(true)
      try {
        const response = await fetch(
          `${environmentConfig.chatApiUrl}/StoreReport/GetApplicationList`,
          {
            method: "GET",
            headers: {
              Accept: "text/plain",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data: any = await response.text(); // Assuming the response is text/plain
        const parsedData = JSON.parse(data);
        setApplications(parsedData);
        const applicationNames = parsedData?.map((app: any) => ({
          value: app.applicationID,
          label: `${app.applicationName}`,
        }))
        setApplicationOptions(applicationNames)
        // console.log("Parsed data are = ",applicationNames)
        const options = parsedData?.map((app: any) => ({
          title: app.applicationName,
          name: getToolNameFromApplicationName(app.applicationName),
          id: app.applicationID,
          count: app.alertCount,
          color: app.alertColor,
          isDisabled: getIsDisabledFromApplicationName(app.applicationName),
        }));
        // const storeScannerSystemsCardDetails = {
        //   title: "Store Scanner Systems (MSS Services)",
        //   name: getToolNameFromApplicationName(
        //     "STORE SCANNER SYSTEMS (MSS SERVICES)"
        //   ),
        //   id: 12,
        //   count: 0,
        //   color: "green",
        //   isDisabled: getIsDisabledFromApplicationName(
        //     "STORE SCANNER SYSTEMS (MSS SERVICES)"
        //   ),
        // };
        setApplicationsToAdd([...applicationNames])
        setApplicationsToAddDisplay([...applicationNames])
        setCardsDetails([...options]);
        setLoading(false)
      } catch (error) {
        console.error("Error fetching notification details:", error);
        setLoading(false)
      }
    };
    const fetchApplicationDetails = async () => {
      let token = await GetAccessToken();
      try {
        const response = await fetch(
          `${environmentConfig.chatApiUrl}/StoreNotification/GetNotificationFilter`,
          {
            method: "GET",
            headers: {
              Accept: "text/plain",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data: any = await response.text(); // Assuming the response is text/plain
        const parsedData = JSON.parse(data);
        // console.log("data", data);
        const options = parsedData?.applications.map((app: any) => ({
          value: app.applicationId,
          label: `${app.applicationName}`,
        }));
        setApplicationOptions(options);
        // console.log("")
      } catch (error) {
        console.error("Error fetching notification details:", error);
      }
    };
    fetchApplicationLookup();
    // fetchApplicationDetails();
  }, []);
  const onCollapseChange = (key: string | string[]) => {
    // console.log(key);
  };
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [selectedApplication, setSelectedApplication] = useState("");
  const [cardsDetails, setCardsDetails] = useState<any>([]);
  const handleAddApplicationChange = (selected: string[]) => {
    setSelectedOptions(selected);
  };

  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen:any) => {
    setOpen(newOpen);
  };

  const closePopover = () => {
    setOpen(false);
  };

  
  const handleCheckboxChange = (value: string) => {
    if (value === "Select All") {
      setSelectedOptions([
        "Select All",
        ...options.map((option) => option.value),
      ]);
    } else if (selectedOptions.includes(value)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== value));
    } else {
      setSelectedOptions([...selectedOptions, value]);
    }
    // console.log("selected values are = ", selectedOptions)
  };

  const options: OptionType[] = [
    { value: "InStore Customer Care", label: "InStore Customer Care" },
    { value: "Smartphones in Store", label: "Smartphones in Store" },
    { value: "SIM", label: "SIM" },
    { value: "Delcon Web", label: "Delcon Web" },
  ];

  const checkAll = plainOptions.length === checkedList.length;
  const indeterminate =
    checkedList.length > 0 && checkedList.length < plainOptions.length;
  const handleChange = (value: string, type: string) => {
    // console.log(`selected ${value}`);
    if (type === "Application") {
      setSelectedApplication(value);
    }
  };
  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    // console.log(date, dateString);
  };
  const onCheckBoxChange = (list: CheckboxValueType[]) => {
    setCheckedList(list);
  };

  const onCheckAllChange: CheckboxProps["onChange"] = (e) => {
    setCheckedList(
      e.target.checked ? plainOptions.map((option) => option.value) : []
    );
  };
  const ToolDetails = [
    {
      title: "STORE SMARTPHONE",
      name: "STORESMARTPHONE",
      disabled: false,
    },
    {
      title: "CUSTOMER COUNTER",
      name: "customer-counter",
      disabled: false,
    },
    {
      title: "INSTORE CUST CARE - PROD",
      name: "INSTORECUSTCARE-PROD",
      disabled: false,
    },
    {
      title: "Delcon Web",
      name: "DelconWeb",
      disabled: true,
    },
    {
      title: "Click and Collect",
      name: "ClickandCollect",
      disabled: true,
    },
    {
      title: "APPLICATION NAME",
      name: "APPLICATIONNAME",
      disabled: true,
    },
    {
      title: "APPLICATION NAME",
      name: "APPLICATIONNAME",
      disabled: true,
    },
    {
      title: "APPLICATION NAME",
      name: "APPLICATIONNAME",
      disabled: true,
    },
    {
      title: "STORE 0365",
      name: "STORE0365",
      disabled: true,
    },
    {
      title: "RCS ECC SIM - P10 - PROD",
      name: "RCSECCSIM-P10-PROD",
      disabled: true,
    },
    {
      title: "STORE SCANNER SYSTEMS (MSS SERVICES)",
      name: "store-scanner-systems",
      disabled: false,
    },
    {
      title: "ORIS 3 App",
      name: "ORIS3App",
      disabled: true,
    },
    {
      title: "STORE SCANNER SYSTEMS (MSS SERVICES)",
      name: "store-scanner-systems",
      disabled: false,
    },
    {
      title: "AEOS",
      name: "aeos",
      disabled: false,
    },
    {
      title: "POS",
      name: "pos",
      disabled: false,
    },
  ];
  const getToolNameFromApplicationName = (applicationName: string) => {
    const toolDetails = ToolDetails.find(
      (tool) => tool.title === applicationName
    );
    return toolDetails?.name;
  };
  const getIsDisabledFromApplicationName = (applicationName: string) => {
    const toolDetails = ToolDetails.find(
      (tool) => tool.title === applicationName
    );
    return toolDetails?.disabled;
  };

  const onCheckChange = (e: any) => {
    // console.log(`checked = ${e.target.checked}`);
  };

  const handleCardClick = (value?: any) => {
    // console.log("value", value);
    navigate("/" + value);
  };

  const content = (
    <div>
      <input type="text" name="applications" className={styles.addAppInputSearch} id="" onChange={(e) => {
        if (e.target.value) {
          const filteredList = applicationsToAdd.filter((val: any) => {
            // console.log(val.label, e.target.value.toLocaleUpperCase())
            return val.label.includes(e.target.value.toLocaleUpperCase())
          })
          // console.log(filteredList)
          setApplicationsToAddDisplay(filteredList)
        } else {
          setApplicationsToAddDisplay(applicationsToAdd)
        }
      }} autoComplete="off" />
      <div className={styles.addAppsListDiv}>
        {applicationsToAddDisplay.map((option: any) => (
          <label style={{ display: "flex", margin:"10px 0px", cursor:"pointer" }} key={option.value}>
            <Checkbox
              className="custom-checkbox-addApp"
              onChange={() => handleCheckboxChange(option.value)}
              checked={selectedOptions.includes(option.value)}
            />
            <div style={{ marginLeft: "5px", fontSize:"12px", paddingTop:"2px" }}>
              {option.label}
            </div>
          </label>
        ))}
      </div>
      <Flex gap="small" style={{ height: "45px" }}>
        <Button className={styles.CancelBtn} onClick={closePopover}>CANCEL</Button>
        <Button className={styles.AddBtn}>+ADD</Button>
      </Flex>
    </div>
  );
  return (
    <div className={styles.StoreNotifications}>
      <Header pageTitle="Global Visualizer " />
      <div className={styles.StoreNotificationsContainer}>
        <div style={{ marginBottom: "20px" }}>
          <a className={styles.breadCrumbsLink} style={{ paddingLeft: "0px" }} onClick={() => navigate("/")}>Home</a>
          <a>&gt;</a>
          <a
            className={styles.breadCrumbsLink}
            style={{ textDecoration: "underline", cursor: "auto" }}
          >Application Health</a>

        </div>

        <div
          className={
            styles.ApplicationHealthHeader + " ApplicationHealthHeader"
          }
        >
          <h2 className={styles.StoreNotificationTitle}>Application Health</h2>
          <Select
            className={`${styles.HeaderCombo} custom-select`}
            suffixIcon={<DownOutlined />}
            placeholder="Select Application"
            style={{ width: 228 }}
            onChange={(value) => handleChange(value, "Application")}
            options={[{ value: "", label: "All" }, ...applicationOptions]}
          />
        </div>
        <Divider style={{ marginTop: "12px", marginBottom: "30px", height: "1px", backgroundColor: "#cccccc" }}></Divider>
        <div
          style={{
            marginBottom: "15px",
            width: "100%",
          }}
        >{loading ? <div className={styles.spinnerContainer}>
          <Spin size="large" />
        </div> :
          <Row gutter={[16, 16]}>
            {cardsDetails?.map((item: any) => {
              return (
                <Col xs={24} sm={12} md={8} lg={6} key={item.id} style={{ cursor: `${item?.isDisabled ? "" : "pointer"}` }}>
                  <div style={{ width: "auto" }} key={item?.id}>
                    <CardSection
                      handleClick={() => {
                        handleCardClick(item.name)}}
                      item={item}
                      styleName={"applicationHelthCard"}
                    />
                  </div>
                </Col>
              );
            })}
          </Row>}
        </div>
        <div className={styles.addMoreApplicationBtnContainer}>
          <Popover  open={open} onOpenChange={handleOpenChange} className={styles.addMoreAppPopoverBigScrn} placement="right" content={content} trigger="click">
            <Button className={styles.submitNotificationBtn}>
              + Add More Application
            </Button>
          </Popover>

          <Popover className={styles.addMoreAppPopoverSmallScrn} placement="bottom" content={content} trigger="click">
            <Button className={styles.submitNotificationBtn}>
              + Add More Application
            </Button>
          </Popover>
        </div>
      </div>
    </div>
  );
};
export default ApplicationHealth;
