import React, { useState, useEffect } from "react";
import styles from "./StoreSmartPhone.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Spin, Divider } from "antd";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import Header from "../../components/CommonComponents/Header";
import DatePickerComponent from "../../components/CommonComponents/DatePicker";
import DropDownList from "../../components/CommonComponents/DropDownList";
import CustomTable from "../../components/CommonComponents/Table";
import { GetLoggedInCountry } from "../../services/AuthUser";
import timezoneToCountry from "../../data/timezone";
import moment from "moment";
import { GetAccessToken } from "../../services/AuthToken";
import {
  environmentData,
  EnvironmentData,
  EnvironmentConfig,
} from "../../environments/env";
import { useAppContext } from "../../components/ContextAndProvider/Context";
import ExportToCSV from "../../components/CommonComponents/ExportToCSV";
import ExportToExcel from "../../components/CommonComponents/ExportToExcel";

const StoreSmartPhone = () => {
  let runEnv: keyof EnvironmentData =
    environmentData.run as keyof EnvironmentData;
  const environmentConfig: EnvironmentConfig = environmentData[
    runEnv
  ] as EnvironmentConfig;
  const location = useLocation()
  const navigate = useNavigate();
  const [selectedCountries, setSelectedCountries] = useState("");
  const [selectedCountriesName, setSelectedCountriesName] = useState("");
  const [countrySelectedStores, setCountrySelectedStores] = useState([]);
  const [selectedStores, setSelectedStores] = useState<any>([]);
  const [selectedStoreId, setSelectedStoreId] = useState<string[]>([]);
  const [loading, setLoading] = useState(false); // New loading state
  const [storeCountries, setStoreCountries] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState([]);
  const [storeSmartPhoneReportsData, setStoreSmartPhoneReportsData] = useState(
    []
  );
  const [
    storeSmartPhoneReportsExportData,
    setStoreSmartPhoneReportsExportData,
  ] = useState([]);
  const [storeFilterResponse, setStoreFilterResponse] = useState<any>([]);
  const [selectedCountryId, setSelectedCountryId] = useState<string[]>([]);
  const [uncheckingList, setUncheckingList] = useState<any>([]);
  const { clearDropDownStore, setClearDropDownStore } = useAppContext();
  const [disconnectedDevicesData, setDisconnectedDevicesData] = useState([]);
  const [disconnectedDevicesExportData, setDisconnectedDevicesExportData] =
    useState([]);
  const [failureReportData, setFailureReportData] = useState([]);
  const [defaultStores, setDefaultStores] = useState<any>([]);
  const [defaultSelectedCountryId, setDefaultSelectedCountryId] = useState<any>(
    []
  );
  const [showIpAddressList, setShowIpAddressList] = useState<any>([]);

  let reA = /[^a-zA-Z]/g;
  let reN = /[^0-9]/g;

  const sortAlphaNum = (a: any, b: any) => {
    var aA = a?.replace(reA, "");
    var bA = b?.replace(reA, "");
    if (aA === bA) {
      var aN = parseInt(a?.replace(reN, ""), 10);
      var bN = parseInt(b?.replace(reN, ""), 10);
      return aN === bN ? 0 : aN > bN ? 1 : -1;
    } else {
      return aA > bA ? 1 : -1;
    }
  };

  const handleDateFormat = (date: any) => {
    const newDate = new Date(date);
    const timezoneOffsetMs = newDate.getTimezoneOffset() * 60 * 1000;
    const localTimeMs = newDate.getTime() - timezoneOffsetMs;
    const localDate = new Date(localTimeMs);
    const formattedLocalDateTime = localDate
      .toLocaleString("sv")
      .replace(" ", "T");
    return moment(formattedLocalDateTime).format("DD-MM-YYYY HH:mm");
  };

  function convertUTCToLocalTime(utcDate: string): string | null {
    try {
      const defaultCountry = GetLoggedInCountry();
      const timeZone = getCounrtyTimeZone(timezoneToCountry, defaultCountry);
      // Create a new Date object from the UTC date
      const date = new Date(`${utcDate}Z`);

      // Use Intl.DateTimeFormat to get the local time for the given time zone
      const options: Intl.DateTimeFormatOptions = {
        timeZone: timeZone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };

      // Format the date to the local time of the given time zone
      const localTime = new Intl.DateTimeFormat("en-US", options).format(date);
      // console.log(
      //   "convertUTCToLocalTime",
      //   utcDate,
      //   timeZone,
      //   localTime,
      //   moment(localTime).format("DD-MM-YYYY HH:mm")
      // );
      return moment(localTime).format("DD-MM-YYYY HH:mm");
    } catch (error) {
      console.error("Error converting UTC to local time:", error);
      return null;
    }
  }

  const getCounrtyTimeZone: any = (timezoneToCountry: any, country: any) => {
    return Object.keys(timezoneToCountry).find(
      (key) => timezoneToCountry[key] === country
    );
  };

  const headerListConnectedDevices = [
    {
      label: "DEVICE ID",
      key: "deviceName",
    },
    {
      label: "IP ADDRESS",
      key: "ipAddress",
    },
    {
      label: "ACCESS POINT",
      key: "accessPoint",
    },
    {
      label: "CONNECTED SINCE",
      key: "connectedSince",
    },
    {
      label: "USAGE (ARUBA)",
      key: "usage",
    },
    {
      label: "AIRWATCH LAST SEEN",
      key: "lastSeen",
    },
    {
      label: "MODEL",
      key: "modelName",
    },
    {
      label: "ENROLLED SINCE",
      key: "enrolledOn",
    },
    {
      label: "LAST BANDWIDTH TEST",
      key: "lastBandwidthTest",
    },
    {
      label: "TESTED SPEED",
      key: "testedSpeed",
    },
  ];

  const headerListDisConnectedDevices = [
    {
      label: "DEVICE NAME",
      key: "deviceName",
    },
    {
      label: "ACCESS POINT",
      key: "accessPoint",
    },
    {
      label: "FAILED REASON",
      key: "failureReason",
    },
    {
      label: "REPORTED TIME (APPROX)",
      key: "reportedOn",
    },
  ];

  const columns: any = [
    {
      title: "DEVICE ID",
      dataIndex: "deviceName",
      key: "deviceName",
      width: 150,
      align: "center",
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: "IP ADDRESS",
      dataIndex: "ipAddress",
      key: "ipAddress",
      width: 200,
      align: "center",
      render: (text: any, record: any) => (
        <a>
          {text && text?.length !== 0 ? (
            showIpAddressList?.find((val: any) => {
              return val?.deviceName === record?.deviceName;
            }) ? (
              <a
                onClick={() => {
                  handleHideIpAddress(record);
                }}
              >
                {text && text?.length !== 0 ? text : "No Device Name Available"}
              </a>
            ) : (
              <span
                className={styles.eyeIcon}
                onClick={() => {
                  handleShowIpAddress(record);
                }}
              ></span>
            )
          ) : (
            "No Device Name Available"
          )}
        </a>
      ),
    },
    {
      title: "ACCESS POINT",
      dataIndex: "accessPoint",
      key: "accessPoint",
      width: 150,
      align: "center",
      render: (text: any) => (
        <a>{`${text === null ? "No Data available" : text}`}</a>
      ),
      sorter: (a: any, b: any) => sortAlphaNum(a?.accessPoint, b?.accessPoint),
    },
    {
      title: "CONNECTED SINCE",
      dataIndex: "connectedSince",
      key: "connectedSince",
      width: 170,
      align: "center",
      render: (text: any) =>
        text && text?.length > 0
          ? convertUTCToLocalTime(text)
          : "No Date available",
      sorter: (a: any, b: any) =>
        moment(a.connectedSince).unix() - moment(b.connectedSince).unix(),
    },
    {
      title: "USAGE (ARUBA)",
      dataIndex: "usage",
      key: "usage",
      width: 140,
      align: "center",
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: "AIRWATCH LAST SEEN",
      dataIndex: "lastSeen",
      key: "lastSeen",
      width: 170,
      align: "center",
      render: (text: any) =>
        text && text?.length > 0
          ? convertUTCToLocalTime(text)
          : "No Date available",
      sorter: (a: any, b: any) =>
        moment(a.lastSeen).unix() - moment(b.lastSeen).unix(),
    },
    {
      title: "MODEL",
      dataIndex: "modelName",
      key: "modelName",
      width: 200,
      align: "center",
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: "ENROLLED SINCE",
      dataIndex: "enrolledOn",
      key: "enrolledOn",
      width: 150,
      align: "center",
      render: (text: any) =>
        text && text?.length > 0
          ? convertUTCToLocalTime(text)
          : "No Date available",
    },
    {
      title: "LAST BANDWIDTH TEST",
      dataIndex: "lastBandwidthTest",
      key: "lastBandwidthTest",
      width: 170,
      align: "center",
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: "TESTED SPEED",
      dataIndex: "testedSpeed",
      key: "testedSpeed",
      width: 150,
      align: "center",
      render: (text: any) => <a>{text}</a>,
    },
  ];

  const disConnectedDevicesColumns: any = [
    {
      title: "DEVICE NAME",
      dataIndex: "deviceName",
      key: "deviceName",
      width: 200,
      align: "center",
      render: (text: any, record: any) => (
        <a>
          {text && text?.length !== 0 ? (
            showIpAddressList?.find((val: any) => {
              return val?.deviceName === record?.deviceName;
            }) ? (
              <a
                onClick={() => {
                  handleHideIpAddress(record);
                }}
              >
                {text && text?.length !== 0 ? text : "No Device Name Available"}
              </a>
            ) : (
              <span
                className={styles.eyeIcon}
                onClick={() => {
                  handleShowIpAddress(record);
                }}
              ></span>
            )
          ) : (
            "No Device Name Available"
          )}
        </a>
      ),
    },
    {
      title: "ACCESS POINT",
      dataIndex: "accessPoint",
      key: "accessPoint",
      width: 150,
      align: "center",
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: "FAILED REASON",
      dataIndex: "failureReason",
      key: "failureReason",
      width: 350,
      align: "center",
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: "REPORTED TIME (APPROX)",
      dataIndex: "reportedOn",
      key: "reportedOn",
      width: 200,
      align: "center",
      render: (text: any) =>
        text && text?.length > 0
          ? convertUTCToLocalTime(text)
          : "No Date available",
      sorter: (a: any, b: any) =>
        moment(a.reportedOn).unix() - moment(b.reportedOn).unix(),
    },
  ];

  const handleShowIpAddress = (record: any) => {
    setShowIpAddressList([
      ...showIpAddressList,
      { deviceName: record.deviceName },
    ]);
  };

  const handleHideIpAddress = (record: any) => {
    setShowIpAddressList(
      showIpAddressList?.filter(
        (val: any) => val?.deviceName !== record?.deviceName
      )
    );
  };

  const fetchStoresList = async () => {
    let token = await GetAccessToken();
    try {
      const response = await fetch(
        `${environmentConfig.chatApiUrl}/StoreReport/GetStoreFilter`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data: any = await response.json();
      setStoreFilterResponse(data);
      let defaultCountry = GetLoggedInCountry();
      const params = new URLSearchParams(location.search);
      const countries: any =
      (params.get("countries") && params.get("countries")?.split(",")) || [];
    const stores: any =
      (params.get("storeId") && params.get("storeId")?.split(",")) || [];

      let allStoreCountries: any = [];
      data?.storeCountries?.forEach((item: any) => {
        allStoreCountries.push({
          ...item,
          key: item.countryId,
          value: item.countryName,
        });
        if (item?.countryName === defaultCountry && countries?.length===0 ) {
          setDefaultStores([item?.stores[0].storeId]);
          setSelectedStores([item?.stores[0].storeId]);
        } 
        if(countries?.length>0){

          let allCountries: any = [];
          storeFilterResponse?.storeCountries?.forEach((item: any) => {
            allCountries.push({
              value: item?.countryName,
              key: item?.countryId,
            });
          });

          let defaultSelectedCountryList =
            countries?.length > 0 ? countries : [defaultCountry];
          let countriesList: any = [];
          allCountries?.forEach((item: any) => {
            if (defaultSelectedCountryList?.includes(item?.value)) {
              countriesList.push(item.value);
            }
          });

          let allCountriesStores  :any= []
          const filteredCountries = data?.storeCountries?.filter(
            (country: any) => {
              if(countries.length>0){

                return countries.includes(country.countryName)
              } else {
                return country.countryName == defaultCountry;
            }
          // if(countries.length>0 && countries.includes(country.countryName)){
          //   console.log("countries includes= ",countries)
  
          //         return country.countryName
          //       } else {
          //         return defaultCountry;
          //     }
          }
          );
  
          filteredCountries?.forEach((item: any) => {
            allCountriesStores?.push(
              ...item.stores?.map((item: any) => ({
                value: item?.storeId,
                key: item?.storeId,
              }))
            );
          });
          setCountrySelectedStores(allCountriesStores);
          setDefaultSelectedCountryId(countries)
          setSelectedCountries(countries)
          setSelectedCountriesName(countries.join(", "))
          let allSelectedStoresList = allCountriesStores
          ?.filter((item: any) => stores?.includes(item?.value))
          ?.map((item: any) => item?.value);
          setSelectedStores(allSelectedStoresList);
          setDefaultStores(allSelectedStoresList)
        }
      });
      setStoreCountries(allStoreCountries);
    } catch (error: any) {
      console.error("Error while fetching Store list data:", error);
    }
  };

  useEffect(() => {
    fetchStoresList();
  }, []);

  useEffect(() => {
    // console.log("def =",defaultSelectedCountryId, selectedCountryId?.toString())
    const params = new URLSearchParams();
    if (selectedCountries) {
      params.set(
        "countries",
        selectedCountries?.toString() || defaultSelectedCountryId?.trim()?.toString()
      );
      params.set(
        "storeId",
        selectedStores?.toString() || defaultStores?.toString()
      );
      navigate({ search: params.toString() }, { replace: true });
    }
  }, [
    selectedCountries,
    selectedCountryId,
    defaultSelectedCountryId,
    selectedStores,
    storeFilterResponse,
    selectedCountryCode
  ]);

  const fetchSmartPhoneDetails = async (params: any) => {
    let token = await GetAccessToken();
    try {
      setLoading(true);
      const response = await fetch(
        `${environmentConfig.chatApiUrl}/StoreSmartPhone/GetSmartDeviceReport`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(params),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setStoreSmartPhoneReportsData(data?.connectedDevices || []);
      setDisconnectedDevicesData(data?.failedDevices || []);
      const connectedDevicesExportData = data?.connectedDevices?.map(
        (item: any) => ({
          ...item,
          deviceName: item?.deviceName ? item?.deviceName : "NA",
          accessPoint: item?.accessPoint ? item?.accessPoint : "NA",
          usage: item?.usage ? item?.usage : "NA",
          modelName: item?.modelName ? item?.modelName : "NA",
          ipAddress: item?.ipAddress ? item?.ipAddress : "NA",
          connectedSince: item?.connectedSince
            ? convertUTCToLocalTime(item?.connectedSince)
            : "NA",
          lastSeen: item?.lastSeen
            ? convertUTCToLocalTime(item?.lastSeen)
            : "NA",
          enrolledOn: item?.enrolledOn
            ? convertUTCToLocalTime(item?.enrolledOn)
            : "NA",
        })
      );
      const failedDevicesExportData = data?.failedDevices?.map((item: any) => ({
        ...item,
        deviceName: item?.deviceName ? item?.deviceName : "NA",
        accessPoint: item?.accessPoint ? item?.accessPoint : "NA",
        failureReason: item?.failureReason ? item?.failureReason : "NA",
        reportedOn: item?.reportedOn
          ? convertUTCToLocalTime(item?.reportedOn)
          : "NA",
      }));
      setStoreSmartPhoneReportsExportData(connectedDevicesExportData || []);
      setDisconnectedDevicesExportData(failedDevicesExportData || []);
      if (params?.StoreId?.length > 3000 && data?.length > 0) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      console.error("Error fetching store-smartphone details:", error);
    }
  };
  useEffect(() => {
    const defaultCountryStoresList = countrySelectedStores?.map(
      (item: any) => item?.value
    );
    // if (selectedStores?.length > 0 || defaultCountryStoresList?.length > 0) {
    const params = {
      // CountryCode: selectedCountryCode,
      StoreId: selectedStores?.length > 0 ? selectedStores : defaultStores,
    };
    if (selectedStores.length > 0) {
      fetchSmartPhoneDetails(params);
    }
    // }
  }, [countrySelectedStores, selectedCountryCode,selectedCountries, selectedStores, location.search]);

  const fetchFailureChartReport = async () => {
    let token = await GetAccessToken();
    try {
      setLoading(true);
      const response = await fetch(
        `${environmentConfig.chatApiUrl}/StoreSmartPhone/GetFailureChartReport`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      let updatedFailureData = data.map((item: any) => ({
        date: moment(item?.reportedOn).format("DD-MM-YYYY"),
        count: item?.count,
      }));
      setFailureReportData(updatedFailureData || []);
    } catch (error: any) {
      console.error("Error fetching failure report:", error);
    }
  };

  useEffect(() => {
    fetchFailureChartReport();
  }, []);

  useEffect(() => {
    if (!selectedCountries && storeFilterResponse?.storeCountries) {
      let defaultCountry = GetLoggedInCountry();
      const params = new URLSearchParams(location.search);
      const countries: any =
        (params.get("countries") && params.get("countries")?.split(",")) || [];
      const stores: any =
        (params.get("storeId") && params.get("storeId")?.split(",")) || [];
      setSelectedCountries(countries)
      if (defaultCountry) {
        setSelectedCountryId([defaultCountry]);
        setDefaultSelectedCountryId([defaultCountry]);
        setSelectedCountries(defaultCountry);
        let allCountriesStores: any = [];
        const filteredCountries = storeFilterResponse?.storeCountries?.filter(
          (country: any) => country.countryName == defaultCountry
        );

        filteredCountries?.forEach((item: any) => {
          allCountriesStores?.push(
            ...item.stores?.map((item: any) => ({
              value: item?.storeId,
              key: item?.storeId,
            }))
          );
        });
        setCountrySelectedStores(allCountriesStores);
      }
    }
  }, [storeFilterResponse]);

  useEffect(() => {
    if (clearDropDownStore) {
      let defaultCountry = GetLoggedInCountry();
      setSelectedCountryId([defaultCountry]);
      setSelectedCountries(defaultCountry);

      setSelectedStoreId([]);
      setSelectedStores([]);
      setUncheckingList(selectedStoreId);
    }
    setClearDropDownStore(false);
  }, [clearDropDownStore]);

  const handleDateChange = (date: any) => {
    // setStartDate(formatDateToDDMMYYYY(date));
  };

  // Filtering stores based on country change
  const filterStoresbasedOnCountryChange = async (filteredCountries: []) => {
    const countryCode = filteredCountries.map((val: any) => {
      return val.countryId;
    });

    const filteredCountriesName = filteredCountries.map((val: any) => {
      return val.countryName;
    });


    const removedStoreId: any = [];
    const filteredStoreId: any = [];
    selectedStores.forEach((storeId: any) => {
      if (countryCode.includes(storeId.slice(0, 2))) {
        filteredStoreId.push(storeId);
      } else {
        removedStoreId.push(storeId);
      }
    });
    setUncheckingList(removedStoreId);
    setSelectedStoreId(filteredStoreId);
    setSelectedStores(filteredStoreId);    
 
    const fetchParams = {
      // CountryCode: selectedCountryCode,
      StoreId:  filteredStoreId
    };
    await fetchSmartPhoneDetails(fetchParams);

    const params = new URLSearchParams();
    params.set(
      "countries",
      filteredCountriesName?.toString() || defaultSelectedCountryId?.trim()?.toString()
    );
    params.set(
      "storeId",
      filteredStoreId?.toString() || ""
    );
    navigate({ search: params.toString() }, { replace: true });
  };

  const handleCountrySelectChange = (updatedCountry: any[]) => {
    if (updatedCountry?.length > 0) {
      // setSelectedCountries(updatedCountry.join(", "));
      setSelectedCountries(updatedCountry.join(","));
      setSelectedCountriesName(updatedCountry.join(", "));
      const allSelectedCountryCode: any = storeCountries
        .filter((item: any) => updatedCountry.includes(item.countryName))
        ?.map((country: any) => country.countryId);
      setSelectedCountryCode(allSelectedCountryCode);
      const filteredCountries = storeFilterResponse.storeCountries.filter(
        (country: any) => updatedCountry?.includes(country.countryName)
      );
      let allCountriesStores: any = [];
      filteredCountries?.forEach((item: any) => {
        // setDefaultStores([...defaultStores, item?.stores[0]?.storeId]);
        allCountriesStores?.push(
          ...item.stores?.map((item: any) => ({
            value: item?.storeId,
            key: item?.storeId,
          }))
        );
      });
      setCountrySelectedStores(allCountriesStores);
      filterStoresbasedOnCountryChange(filteredCountries);
    } else {
      handleClearCountry();
    }
  };
  const handleStoreSelectChange = (stores: any) => {
    if (stores.length === 0) {
      handleUnSelectStore();
    } else {
      setSelectedStores(stores);
    }
  };

  const handleUnSelectStore = () => {
    setDefaultStores([]);
    setSelectedStores([]);
    setStoreSmartPhoneReportsData([])
  };

  const handleClearCountry = () => {
    setDefaultSelectedCountryId([]);
    setSelectedCountries("");
    setUncheckingList(selectedStores);
    setDefaultStores([]);
    setSelectedStores([]);
    setCountrySelectedStores([]);
    setStoreSmartPhoneReportsData([])

    const params = new URLSearchParams();
      params.set(
        "countries",
        ""
      );
      params.set(
        "storeId",  
        ""
      );
      navigate({ search: params.toString() }, { replace: true });    
  };

  return (
    <div className={styles.StoreNotifications}>
      <Header pageTitle="Business Command Center" />
      <div className={styles.mainDiv}>
        <div className={styles.routePageTitleWrapper}>
          <a
            className={styles.breadCrumbsLink}
            style={{ paddingLeft: "0px" }}
            onClick={() => navigate("/")}
          >
            Home
          </a>
          <a>&gt;</a>
          <a
            className={styles.breadCrumbsLink}
            onClick={() => navigate("/applicationhealth")}
          >
            Application Health
          </a>
          <a>&gt;</a>
          <a
            className={styles.breadCrumbsLink}
            style={{ textDecoration: "underline", cursor: "auto" }}
          >
            Store SmartPhone
          </a>
        </div>
        <div className={styles.customerCountHeader}>
          <h2 className={styles.customerCounterTitle}>Store SmartPhone</h2>
          <div className={styles.inputContainerDiv}>
            {/* <div className={styles.datePickerContainerDiv}>
              <DatePickerComponent
                onDateChange={handleDateChange}
                calendarDate={dayjs()}
              />
            </div> */}
            <div className={styles.dropDownContainerDiv}>
              <DropDownList
                items={[
                  {
                    key: `All`,
                    value: `All`,
                    checked: false,
                  },
                  ...storeCountries,
                ]}
                isDisabled={storeCountries?.length === 0}
                onSelect={handleCountrySelectChange}
                unSelectAll={handleClearCountry}
                selectedDropDownItems={defaultSelectedCountryId}
                styleName={"customerCounterStoreStyle"}
                displayName={
                  selectedCountries.length === 0
                    ? "Choose Country"
                    // : selectedCountries
                    : selectedCountriesName?selectedCountriesName:defaultSelectedCountryId?.join(", ")
                }
                dropdownName="countryDropdown"
              />
            </div>
            <div className={styles.dropDownContainerDiv}>
              <DropDownList
                items={[
                  {
                    key: `All`,
                    value: `All`,
                    checked: false,
                  },
                  ...countrySelectedStores,
                ]}
                isDisabled={countrySelectedStores?.length === 0}
                uncheckingList={uncheckingList}
                selectedDropDownItems={defaultStores}
                unSelectAll={handleUnSelectStore}
                onSelect={handleStoreSelectChange}
                styleName={"customerCounterStoreStyle"}
                displayName={
                  selectedStores.length === 0
                    ? "Choose Stores"
                    : selectedStores.join(", ")
                }
                dropdownName="storeDropdown"
              />
            </div>
            <div className={styles.exportButtonDiv}>
              <ExportToExcel
                headers={headerListConnectedDevices}
                jsonData={storeSmartPhoneReportsExportData}
                fileName={`store-smartphone-connected-devices ${moment(
                  new Date()
                ).format("DD-MM-YY")}`}
              />
            </div>
          </div>
        </div>
        <Divider
          style={{
            marginTop: "12px",
            marginBottom: "30px",
            height: "1px",
            backgroundColor: "#cccccc",
          }}
        ></Divider>
        <div className={styles.tableContainer}>
          {loading ? (
            <div className={styles.spinnerContainer}>
              <Spin size="large" />
            </div>
          ) : (
            <CustomTable columns={columns} data={storeSmartPhoneReportsData} />
          )}
        </div>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "10px 0px",
            }}
          >
            <h2 className={styles.customerCounterTitle}>
              Not Connected Devices
            </h2>
            <div className={styles.exportButtonDiv}>
              <ExportToExcel
                headers={headerListDisConnectedDevices}
                jsonData={disconnectedDevicesExportData}
                fileName={`store-smartphone-disconnected-devices ${moment(
                  new Date()
                ).format("DD-MM-YY")}`}
              />
            </div>
          </div>
          <Divider
            style={{
              marginTop: "12px",
              marginBottom: "30px",
              height: "1px",
              backgroundColor: "#cccccc",
            }}
          />
          <div className={styles.disconnectedDeviceWrapper}>
            <div className={styles.disconnectedDevice}>
              {loading ? (
                <div className={styles.spinnerContainer}>
                  <Spin size="large" />
                </div>
              ) : (
                <CustomTable
                  columns={disConnectedDevicesColumns}
                  data={disconnectedDevicesData}
                  pageSize={5}
                  showSizeChanger={false}
                />
              )}
            </div>
            <div className={styles.faliledNetworkChart}>
              <div
                style={{
                  fontFamily: "HM Sans",
                  fontSize: "12px",
                  fontWeight: 600,
                  color: "#FFFFFF",
                  lineHeight: "23.25px",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                Network Failed trend - Global
              </div>
              <ResponsiveContainer width="100%" height="90%">
                <AreaChart
                  width={730}
                  height={255}
                  data={failureReportData}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                  style={{ color: "#FFFFFF", margin: "0px -12px" }}
                >
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#B20DCD" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#B20DCD" stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#B20DCD" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#AF26A2" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <XAxis
                    dataKey="date"
                    tick={{ fill: "#FFFFFF", fontSize: 10 }}
                  />
                  <YAxis
                    dataKey="count"
                    tick={{ fill: "#FFFFFF", fontSize: 10 }}
                  />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="date"
                    stroke="#8884d8"
                    fillOpacity={1}
                    fill="#8884d8"
                  />
                  <Area
                    type="monotone"
                    dataKey="count"
                    stroke="#82ca9d"
                    fillOpacity={1}
                    fill="url(#colorPv)"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StoreSmartPhone;
