import React, { useContext, useState } from 'react'
import styles from "./MetricsList.module.css"
import Header from '../../components/CommonComponents/Header'
import { Breadcrumb } from 'antd'
import { Typography, Dropdown, MenuProps, Flex } from "antd";
import DatePickerComponent from '../../components/CommonComponents/DatePicker';
import DropDownList from '../../components/CommonComponents/DropDownList';
import CustomTable from '../../components/CommonComponents/Table';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from "../../components/ContextAndProvider/Context";

const {Title} = Typography;

function MetricsList() {
  const navigate = useNavigate()

   const {open360Modal, setOpen360Modal} = useAppContext()

  //  console.log("Is modal open? ",open360Modal)

  const StoresList = [
    { value: "Select All stores", key: "Select All stores", checked: false },
    { value: "SE0178", key: "SE0178", checked: false },
    { value: "SE0180", key: "SE0180", checked: false },
    { value: "GB0312", key: "GB0312", checked: false },
    { value: "GB0660", key: "GB0660", checked: false },
    { value: "SE0144", key: "SE0144", checked: false },
    { value: "IT2982379", key: "IT2982379", checked: false },
    { value: "IT29212345", key: "IT29212345", checked: false },
    { value: "KH2982309", key: "KH2982309", checked: false },
    { value: "KK2982356", key: "KK2982356", checked: false },
    { value: "UY29823799", key: "UY29823799", checked: false },
  ];

  const [filteredData, setFilteredData] = useState([
    {"srNo":"MCS 01","cluster":"Apps","metricName":"Performance","application":"Application response time","logisticGroup":"Instore Care", "logicalGroup":"Store ONLINE applications", "configurableThreshold":"<2000 MS", "relationData":"1) Response time for the API 2) Reponse time by Operation ID"},
    {"srNo":"MCS 02","cluster":"Apps","metricName":"Availability","application":"App target availability","logisticGroup":"Instore Care", "logicalGroup":"Store ONLINE applications", "configurableThreshold":"99%", "relationData":"1) All target/source systems"},
    {"srNo":"MCS 03","cluster":"Reports","metricName":"Data Integrity","application":"Sensor Accuracy","logisticGroup":"Customer Counter", "logicalGroup":"Store KPI apps", "configurableThreshold":"95", "relationData":"1) Entry VS Exit 2) Sales data"},
    {"srNo":"MCS 04","cluster":"Reports","metricName":"Availability","application":"Sensor Accuracy","logisticGroup":"Customer Counter", "logicalGroup":"Store KPI apps", "configurableThreshold":"99%", "relationData":"1) 10<out count/Entrance 2) Vs 10<Store sales data"},
    {"srNo":"MCS 05","cluster":"Hardware","metricName":"Data Integrity","application":"Stale devices","logisticGroup":"Smartphone in store", "logicalGroup":"Store smart Devices", "configurableThreshold":"5%>", "relationData":"1) Airwatch offline>30 days"},
    {"srNo":"MCS 04","cluster":"Hardware","metricName":"Availability","application":"Devices in network","logisticGroup":"Smartphone in store", "logicalGroup":"Store smart Devices", "configurableThreshold":">50%", "relationData":"1) Aruba 2) Airwatch"}
  ]);


  const columns = [
    {
      title: "SR NO.",
      dataIndex: "srNo",
      key: "srNo",
      width:100,
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: "CLUSTER",
      dataIndex: "cluster",
      key: "cluster",
      width:100,
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: "METRIC NAME",
      dataIndex: "metricName",
      key: "metricName",
      width:120,
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: "APPLICATION",
      dataIndex: "application",
      key: "application",
      width:200,
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: "LOGISTIC GROUP",
      dataIndex: "logisticGroup",
      key: "logisticGroup",
      width:150,
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: "LOGICAL GROUP",
      dataIndex: "logicalGroup",
      key: "logicalGroup",
      width:200,
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: "CONFIGURABLE THRESHOLD",
      dataIndex: "configurableThreshold",
      key: "configurableThreshold",
      width:200,
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: "RELATION DATA",
      dataIndex: "relationData",
      key: "relationData",
      width:200,
      render: (text: any) => <a>{text}</a>,
    },
  ];

  const handleCheckboxChange = () => {

  }

  return (
    <div>
      <Header pageTitle="Business Command Center" />
      <div className={styles.metricsListContainer}>
      <div className={styles.routePageTitleWrapper} style={{marginBottom:"20px"}}>
        <a  className={styles.breadCrumbsLink} style={{ paddingLeft: "0px" }} onClick={() => navigate("/")}>Home</a>
        <a>&gt;</a>
        <a
         className={styles.breadCrumbsLink}
         onClick={() => {
          setOpen360Modal(true)
          navigate("/")
          }} >Store 360 view</a>
        <a>&gt;</a>
        <a  className={styles.breadCrumbsLink} onClick={() => navigate("/metricslist")} style={{textDecoration:"underline"}}>Metrics List</a>
      </div>
      
        <div className={styles.metricsListHeader}>
        <div className={styles.headerTitle}>Metrics List</div>
          <div className={styles.inputContainerDiv}>
                <div className={styles.datePickerContainerDiv}>
                  <DatePickerComponent  />
                </div>
                <div className={styles.dropDownContainerDiv} >
                  <DropDownList
                    items={StoresList}
                    onSelect={handleCheckboxChange}
                    styleName={"applicationHealthStyle"}
                
                  />
                </div>
          </div>
        </div>
        <div className={styles.divider} />
        <div className={styles.tableContainer} >
          <CustomTable columns={columns} data={filteredData} />
        </div>
      </div>
    </div>
  )
}

export default MetricsList