import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { HashRouter, Routes, Route } from "react-router-dom";

import { initializeIcons } from "@fluentui/react";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./config/MsalConfig";
import "./assets/scss/_custom.scss";
import Layout from "./pages/layout/Layout";
import NoPage from "./pages/NoPage";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import { MsalProvider } from "@azure/msal-react";
import Store from "./pages/store/Store";
import Chat from "./pages/chat/Chat";
import { AppProvider } from "./components/ContextAndProvider/Provider";
import MobileIservice from "./pages/IService/MobileIservice";
import MobileLogin from "./pages/login/MobileLogin";
import ChatMobile from "./pages/chat/ChatMobile";
import VisitorCount from "./pages/visitorcount/VisitorCount";
import Network from "./pages/Network/Network";
import ReportDashboard from "./pages/Reports/ReportDashboard";
import CustomerCounter from "./pages/Reports/CustomerCounter";
import StoreNotification from "./pages/storenotification/StoreNotification";
import ApplicationHealth from "./pages/applicationhealth/ApplicationHealth";
import StoreSmartPhone from "./pages/storesmartphone/StoreSmartPhone";
import InstoreCustCareProd from "./pages/instorecustcareprod/InstoreCustCareProd";
import Application from "./pages/Reports/Application";
import StoreScannerSystems from "./pages/StoreScannerSystems/StoreScannerSystems";
import MetricsList from "./pages/metricsList/MetricsList";
import Feedback from "./pages/feedback/Feedback";
import Aeos from "./pages/aeos/Aeos";
import Pos from "./pages/pos/Pos";
import PrivateRoute from "./services/PrivateRoute";

export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    if (event.payload && "account" in event.payload) {
      const account = event.payload.account;
      if (account) {
        msalInstance.setActiveAccount(account);
      }
    }
  }
});

initializeIcons();

export default function App() {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <MsalProvider instance={msalInstance}>
      <HashRouter>
        <Routes>
          <Route
            path="/chat"
            element={
              <PrivateRoute>
                <Chat />
              </PrivateRoute>
            }
          />
          <Route
            path="/network"
            element={
              <PrivateRoute>
                <Network />
              </PrivateRoute>
            }
          />
          <Route
            path="/storenotification"
            element={
              <PrivateRoute>
                <StoreNotification />
              </PrivateRoute>
            }
          />
          <Route
            path="/applicationhealth"
            element={
              <PrivateRoute>
                <ApplicationHealth />
              </PrivateRoute>
            }
          />
          <Route
            path="/STORESMARTPHONE/:countries?/:storeId?"
            element={
              <PrivateRoute>
                <StoreSmartPhone />
              </PrivateRoute>
            }
          />
          <Route
            path="/INSTORECUSTCARE-PROD"
            element={
              <PrivateRoute>
                <InstoreCustCareProd />
              </PrivateRoute>
            }
          />
          <Route
            path="/reports"
            element={
              <PrivateRoute>
                <ReportDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/metricslist"
            element={
              <PrivateRoute>
                <MetricsList />
              </PrivateRoute>
            }
          />
          <Route
            path="/customer-counter/:reportDate?/:countries?/:storeId?"
            element={
              <PrivateRoute>
                <CustomerCounter />
              </PrivateRoute>
            }
          />
          <Route
            path="/application"
            element={
              <PrivateRoute>
                <Application />
              </PrivateRoute>
            }
          />
          <Route
            path="/store-scanner-systems/:brands?"
            element={
              <PrivateRoute>
                <StoreScannerSystems />
              </PrivateRoute>
            }
          />
          <Route path="/" element={<Login />}>
            <Route index element={<Home />} />
            <Route
              path="*"
              element={
                <PrivateRoute>
                  <NoPage />
                </PrivateRoute>
              }
            />
          </Route>
          <Route
            path="/feedback"
            element={
              <PrivateRoute>
                <Feedback />
              </PrivateRoute>
            }
          />
          <Route
            path="/aeos/:countries?/:storeId?"
            element={
              <PrivateRoute>
                <Aeos />
              </PrivateRoute>
            }
          />
          <Route
            path="/pos/:countries?/:storeId?"
            element={
              <PrivateRoute>
                <Pos />
              </PrivateRoute>
            }
          />
        </Routes>
      </HashRouter>
    </MsalProvider>
  );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <AppProvider>
      <App />
    </AppProvider>
  </React.StrictMode>
);
