import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Application.module.css";
import { Typography, Dropdown, MenuProps, Flex } from "antd";
import { Col, Row } from "antd";
import CustomTable from "../../components/CommonComponents/Table";
import Header from "../../components/CommonComponents/Header";
import DatePickerComponent from "../../components/CommonComponents/DatePicker";
import DropDownList from "../../components/CommonComponents/DropDownList";

const { Title } = Typography;

const Application = () => {
  const navigate = useNavigate();

  const columns = [
    {
      title: "STORE ID",
      dataIndex: "storeid",
      key: "storeid",
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: "POPULAR NAME",
      dataIndex: "populername",
      key: "populername",
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: "NO. OF ACTIVE COUNTER",
      dataIndex: "noofactivecounter",
      key: "noofactivecounter",
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: "SENSOR ACCURACY",
      dataIndex: "sensoraccuracy",
      key: "sensoraccuracy",
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      render: (text: any) => <a>{text}</a>,
    },
  ];

  const data = [
    {
      key: "1",
      storeid: "SE0178",
      populername: "H&M - Global City - Stockholme",
      noofactivecounter: "6",
      sensoraccuracy: "95%",
      status: "Online",
    },
    {
      key: "2",
      storeid: "SE0180",
      populername: "H&M - Hornstull",
      noofactivecounter: "6",
      sensoraccuracy: "95%",
      status: "Online",
    },
    {
      key: "3",
      storeid: "SE01312",
      populername: "Oxford Circus",
      noofactivecounter: "6",
      sensoraccuracy: "95%",
      status: "Online",
    },
    {
      key: "4",
      storeid: "SE0660",
      populername: "Regent Street",
      noofactivecounter: "6",
      sensoraccuracy: "95%",
      status: "Online",
    },
    {
      key: "5",
      storeid: "SE0144",
      populername: "H&M - Mall of Scandinavia - Solna",
      noofactivecounter: "6",
      sensoraccuracy: "95%",
      status: "Online",
    },
  ];

  const StoresList = [
    { value: "IT2982309", key: "1", checked: false },
    { value: "IT292323", key: "2", checked: false },
    { value: "GH2982309", key: "3", checked: false },
    { value: "KK2982309", key: "4", checked: false },
    { value: "ER2982309", key: "5", checked: false },
    { value: "IT2982379", key: "6", checked: false },
    { value: "IT29212345", key: "7", checked: false },
    { value: "KH2982309", key: "8", checked: false },
    { value: "KK2982356", key: "9", checked: false },
    { value: "UY29823799", key: "10", checked: false },
  ];

  const handleCheckboxChange = (updatedItems: any[]) => {
    console.log("SlectedItems", updatedItems);
  };
  return (
    <div>
      <Header pageTitle="Business Command Center" />
      <div className={styles.mainDiv}>
        <div className={styles.routePageTitleWrapper}>
          <a onClick={()=>navigate("/")}>Home</a>
          <a>&gt;</a>
          <a onClick={()=>navigate("/applicationhealth")}>Application Health</a>
          <a>&gt;</a>
          <a onClick={()=>navigate("/application")}>Application - 1</a>
        </div>
        <Row>
          <Col span={8}>
            <Title level={2}>Application 1</Title>
          </Col>
          <Col span={4}></Col>
          <Col span={12}>
            <Row gutter={[0, 12]}>
              <Col
                span={24}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <DatePickerComponent />
                <div style={{ marginLeft: "10px" }}>
                  <DropDownList
                    items={StoresList}
                    onSelect={handleCheckboxChange}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className={styles.devider} />
        <div>
          <CustomTable columns={columns} data={data} />
        </div>
      </div>
    </div>
  );
};
export default Application;
