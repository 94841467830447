import { useIsAuthenticated } from '@azure/msal-react';
import React, { ReactNode } from 'react'
import { Navigate, useLocation } from 'react-router-dom';

interface PrivateRouteProps {
  children: ReactNode
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation()
  const redirectingUrl = location.pathname?.concat(location?.search)
  if (!isAuthenticated) {
    localStorage.setItem("redirectingUrl", redirectingUrl)
    return <Navigate to="/" state={{ from: redirectingUrl }} replace />
  }
  return <>{children}</>

}

export default PrivateRoute